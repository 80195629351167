import { gql } from "apollo-boost";

const addLogoutMutation = gql`
  mutation(
    $logout: String!
    $_id: ID
    
  ) {
    addLogout(
    logout: $logout
      _id: $_id
      
    ) {
      logout
      _id
      
    }
  }
`;

export default addLogoutMutation;
