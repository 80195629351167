import React, { useEffect, useState } from "react";
import "./index.scss"
import settings from "../../../assests/images/settings.svg"
import Multiselect from 'multiselect-react-dropdown';
import Modal from "react-modal"
import {useLazyQuery, useMutation} from "@apollo/client"
 
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import updateUserMutation from "../../../graphql/mutations/user/updateUserMutation";
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
import { useDispatch, useSelector } from "react-redux";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
const AdminManage = (props) => {
    const {
        staff,
        allUsersRefetch ,
        user
    } = props

    const loadingSpinner = useSelector(
      (state) => state.loadingSpinnerActive.loading_spinner
    );

    useEffect(() => {
        if(staff) {
            console.log(staff)
        }
    },[staff])

    const [selectedFilter, setSelectedFilter] = useState(null)
    const [feedbacksList, setFeedbacksList] = useState(null)
    const [locationsList, setLocationsList] = useState(null)
    const [typesList, setTypesList] = useState(null)
    
    const onSelectFeedbacksFunc = (selectedList, selectedItem) => {

        setFeedbacksList(selectedList)
           }
           
    const onRemoveFeedbacksFunc = (selectedList, removedItem) => {

    setFeedbacksList(selectedList)
    }

    const onSelectLocationsFunc = (selectedList, selectedItem) => {

      setLocationsList(selectedList)
         }
         
  const onRemoveLocationsFunc = (selectedList, removedItem) => {

  setLocationsList(selectedList)
  }


    const onSelectTypesFunc = (selectedList, selectedItem) => {

        setTypesList(selectedList)
           }
           
    const onRemoveTypesFunc = (selectedList, removedItem) => {

    setTypesList(selectedList)
    }

    const [
      updateUser,
      { loading:updateUserLoading, data:updateUserData },
    ] = useMutation(updateUserMutation);
  
    const dispatch = useDispatch()

    const updateUserFunc = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())

      console.log(typesList, feedbacksList)
      updateUser({
        variables:{
          _id: selectedFilter?._id,
          types: typesList?.map(x => x.value),
          feedbacks: feedbacksList?.map(x => x.value),
          locations: locationsList?.map(x => x.value)
        }
      }).then(x => {
        allUsersRefetch()
        dispatch(ACTION_LOADING_SPINNER_RESET())
        setSelectedFilter(null)
      })
    }

    return (
        <div className="adminContent column" style={{position:"relative", overflowY:"auto"}}>
            {selectedFilter && <Modal
            isOpen={selectedFilter}
            style={{
              overlay: {
                position:"fixed",
                zIndex:"99999999"
            },
              content: {
                position: "fixed",
                zIndex: "10000",
                height: "100%",
                backdropFilter: "blur(5px)",
                WebkitBackdropFilter: "blur(5px)",
                borderRadius: "none",
                width: "auto",
                top: "0",
                left: "0", 
                right: "0",
                bottom: "0",
                border: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <div style={{height: "100%", width:"70vw"}} className="addCampaignClass">
              {loadingSpinner ? (
                <DataSpinner />
              ) : (
                <>
 <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => setSelectedFilter(null)}
              />
              <div style={{width:"100%", padding:"30px", display:"flex", justifyContent:"center", alignItems:"center", fontWeight:"bold"}}>{selectedFilter?.name}</div>
              <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
              }} className="campaignContainer"> 

                <div className="form-label">Types</div>
               <Multiselect
               style={{width:"100%"}}
                            options={[
                            {label:"website", value:"website"},
                            {label:"leeds", value:"leeds"},
                            {label:"unactive", value:"unactive"},
                            {label:"income", value:"income"},
                            {label:"whatsapp", value:"whatsapp"},
                            {label:"walkIn", value:"walkIn"},
                            {label:"recommendation", value:"recommendation"},
                            {label:"old", value:"old"},
                            {label:"expired", value:"expired"},
                            {label:"lastSession", value:"lastSession"},
                            ]} // Options to display in the dropdown
                            selectedValues={staff?.find(x => x._id == selectedFilter._id).types?.map(x => {
                              return {label:x, value:x}
                            }) || []} // Preselected value to persist in dropdown
                            onSelect={onSelectTypesFunc} // Function will trigger on select event
                            onRemove={onRemoveTypesFunc} // Function will trigger on remove event
                            displayValue="label" // Property name to display in the dropdown options
                    /> 

                <div className="form-label">Feedbacks</div>
               <Multiselect
               style={{width:"100%"}}
                            options={[
                            {label:"Fresh", value:"Fresh"},
                            ]} // Options to display in the dropdown
                            selectedValues={staff?.find(x => x._id == selectedFilter._id).feedbacks?.map(x => {
                              return {label:x, value:x}
                            }) || []} // Preselected value to persist in dropdown
                            onSelect={onSelectFeedbacksFunc} // Function will trigger on select event
                            onRemove={onRemoveFeedbacksFunc} // Function will trigger on remove event
                            displayValue="label" // Property name to display in the dropdown options
                    />  
                    <div className="form-label">Locations</div>
               <Multiselect
               style={{width:"100%"}}
                            options={[
                            {label:"maadi", value:"maadi"},
                            {label:"madinty", value:"madinty"},
                            {label:"zayd", value:"zayd"},
                            {label:"tagamoa", value:"tagamoa"},
                            {label:"heliopolis", value:"heliopolis"},
                            ]} // Options to display in the dropdown
                            selectedValues={staff?.find(x => x._id == selectedFilter._id).locations?.map(x => {
                              return {label:x, value:x}
                            }) || []} // Preselected value to persist in dropdown
                            onSelect={onSelectLocationsFunc} // Function will trigger on select event
                            onRemove={onRemoveLocationsFunc} // Function will trigger on remove event
                            displayValue="label" // Property name to display in the dropdown options
                    />                     
                
              </div> 
              
              <div className="auth-button addUser" style={{display:"flex", justifyContent:"center", alignItems:"center"}} onClick={updateUserFunc}>
                              <span style={{margin:"0 3px", color:"white"}}>Save</span>
              </div>
                </>            
              )}
              </div>

          </Modal>}
            {user?.user && staff?.length && staff.filter(x => {
              if(user?.user?.role == "admin" || user?.user?.email == "rawnaa.rona21@gmail.com") {
                return true
              } else {
                if(x._id == user.user._id) {
                  return true 
                } else {
                  return false
                }
              }
            }).map((x,i) => (
                <div key={i} style={{border:"2px solid gray", background:"white", margin:"10px 0", borderRadius:"8px", padding:"10px", width:"100%", height:"fit-content",   display:"flex", flexDirection:"column",}}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>{x.name}</div>
                        {user?.user?.role == "admin" || user?.user?.email == "rawnaa.rona21@gmail.com" ? <div><img style={{cursor:"pointer"}} onClick={() => setSelectedFilter(x)} src={settings} /></div> : null}
                    </div>
                    <div style={{display:"flex", width:"100%"}}>
                      <div style={{width:"100%", display:"flex", flexWrap:"wrap"}}>{x.types?.map(y => (
                        <div style={{padding:"0 10px", minWidth:"100px", margin:"5px 10px", display:"flex", justifyContent:"center", alignItems:"center", color:"white", height:"30px", background:"#6bd240", borderRadius:"10px"}}>{y}</div>
                      ))}</div>
                    </div>
                    <div style={{display:"flex", width:"100%"}}>
                      <div style={{width:"100%", display:"flex", flexWrap:"wrap"}}>{x.feedbacks?.map(y => (
                        <div style={{padding:"0 10px", minWidth:"100px", margin:"5px 10px", display:"flex", justifyContent:"center", alignItems:"center", color:"white", height:"30px", background:"#6bd240", borderRadius:"10px"}}>{y}</div>
                      ))}</div>
                    </div>
                    <div style={{display:"flex", width:"100%"}}>
                      <div style={{width:"100%", display:"flex", flexWrap:"wrap"}}>{x.locations?.map(y => (
                        <div style={{padding:"0 10px", minWidth:"100px", margin:"5px 10px", display:"flex", justifyContent:"center", alignItems:"center", color:"white", height:"30px", background:"#6bd240", borderRadius:"10px"}}>{y}</div>
                      ))}</div>
                    </div>
                    </div>
            ))} 
        </div>
    )
}
 
export default AdminManage