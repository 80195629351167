import { gql } from "apollo-boost";

const resetPasswordMutation = gql`
  mutation($email: String) {
    resetPassword(email: $email) {
      email
    }
  }
`;

export default resetPasswordMutation;
