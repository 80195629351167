import { gql } from "apollo-boost";

const addLoginMutation = gql`
  mutation(
    $login: String!
    $date: String
    $user: ID
    
  ) {
    addLogin(
      login: $login
      date: $date
      user: {
          _id: $user
      }
    ) {
      login
      date
      _id
    }
  }
`;

export default addLoginMutation;
