import { gql } from "apollo-boost";

const staffFeedbackQuery = gql`
  query(
    $_id: ID
    $name: String
    $feedback: String
    $employee: ID
    $createdAt: String
    $createdTo: String
  ) {
    staffFeedback(
      _id: $_id
      name: $name
      feedback: $feedback
      employee: $employee
      createdAt: $createdAt
      createdTo: $createdTo
    ) {
      _id
      firstCall
      lastCall
      name
      feedback
      employee
      createdAt 
      booked
      followUp
      overPrices
      notInterested
      arrived
      noShow
      noAnswer
      medical
      underAge
      willCallUs
      lowClass
      askAboutPrices
      askAboutJob
      alreadyMember
      wrongNumber
      overWeight
      switchedOff
      followToBook
      travell
      renew
      cancelled
      repeated
      willVisitTheBranch
      callBack
      farAway
      whatsappSend
      website
      leeds
      unactive
      income
      whatsapp 
      walkIn  
      recommendation
      old
      expired
      lastSession
    }
  }
`;

export default staffFeedbackQuery;
