import React from "react"
import { useHistory } from "react-router-dom"

export const ThankYou = () => {

  const history = useHistory()

    return (
        <div class="thankyouDiv" style={{backgroundColor:"transparent", height:"100vh", justifyContent:"center"}}>
        <h1 class="thanksYouH">Thank You</h1>
        <p class="thankYouP">for Choosing Ems Body Fit Egypt</p>       


         <div style={{width:"100%", paddingTop:"50px", display:"flex", justifyContent:"center", alignItems:"center"}}>
  <div
  style={{
  width:"30%",
  height:"30px",
  borderRadius:"5px",
  color:"white",
  fontWeight:"1000",
  minWidth:"250px",
  background: "#1779bd",
}}
    className={"auth-button addUser"}
    onClick={() => history.push("/")}
  >
    
    {"Go home"}

  </div>
</div>

      </div>
    )
}