import { gql } from "apollo-boost";

const addClientMutation = gql`
  mutation(
    $name: String
    $many: String
    $email: String
    $phoneNumber: String
    $location: String
    $state: String
    $employee: ID
    $image: String
    $package: String
    $selectedTime: String
    $invitedBy:String
    $type: String
    $comment: String
    $status: Boolean
    $active: Boolean
  ) {
    addClient(
    selectedTime: $selectedTime
      name: $name
      many: $many 
      email: $email
      package:$package
invitedBy:$invitedBy
      phoneNumber: $phoneNumber
      location: $location
      employee: $employee
      state: $state
      image: $image
      type: $type
      comment: $comment
      status: $status
      active: $active
    ) {
    selectedTime
    _id
    comment
    name
    many
    email
    package
    invitedBy
    phoneNumber
    location
    state
    type
    employee
    image
    status
    active
    }
  }
`;

export default addClientMutation;
