import React, { useEffect, useState } from "react";
import ACTION_PHONE_NUMBER_RESET from "../../../actions/GuestCheckoutForm/PhoneNumber/ACTION_PHONE_NUMBER_RESET";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import addClientMutation from "../../../graphql/mutations/client/addClientMutation";
import {useMutation} from "@apollo/client"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import PhoneNumber from "../../reusable/addClientModal/Number";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import "react-dropdown/style.css";

import Dropdown from "react-dropdown";


const UserForm = () => {

  const [location, setLocation] = useState("")
  const [selectedPackage, setPackage] = useState("")
  const [selectedTime, setSelectedTime] = useState("")
  const [notes, setNotes] = useState("")
  

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [isValid, setIsValid] = useState(true);
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setIsValid(validateEmail(value));
  };
  const [mobile, setMobile] = useState("")
  const dispatch = useDispatch()
  const history = useHistory()
  
  const [users, setUsers] = useState([
    {ref:"Ref 1", name:"", number:"" },
    {ref:"Ref 2", name:"", number:"" },
    {ref:"Ref 3", name:"", number:"" },
    {ref:"Ref 4", name:"", number:"" },
    {ref:"Ref 5", name:"", number:"" },
  ])


  const [goal, setGoal] = useState("")

  const handleNameChange = (index, newName) => {
    const updatedUsers = users.map((user, i) =>
      i === index ? { ...user, name: newName } : user
    );
    setUsers(updatedUsers);
  };

  const handleNumberChange = (index, newNumber) => {
    const updatedUsers = users.map((user, i) =>
      i === index ? { ...user, number: newNumber } : user
    );
    setUsers(updatedUsers);
  };


  const locationOptions = () => {
    const allLocations = ["Maadi", "Madinty", "Zayd", "tagamoa", "Heliopolis"]
    return allLocations;
    };

  const phoneNumber = useSelector((state) => state.phoneNumber.phone_number);
  const phoneIsValid = useSelector((state) => state.phoneIsValid.phone_valid);

  const [
    addClient,
    { loading:addClientLoading, data:addClientData },
  ] = useMutation(addClientMutation);

  const inviteUser = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    addClient({
      variables:{
          name: name,
          phoneNumber: phoneNumber,
          comment:notes,
          selectedTime:selectedTime,
          email:email,
          location: location || "any",
          type: "website",
      }
    })
    .then(() => {
      let usersActive = users.filter(x => x.name && x.number)
      if(usersActive.length) {
        
        usersActive.map(async x => {
          await addClient({
            variables:{
                name: x.name,
                phoneNumber: x.phoneNumber,
                invitedBy: phoneNumber,
                type: "recommendation",
            }
          })
        })
      }
      setName("")
      setEmail("")
      setMobile("")
      dispatch(ACTION_LOADING_SPINNER_RESET())
      dispatch(ACTION_PHONE_NUMBER_RESET())      
      })
    

  }


  useEffect(() => {
    if(addClientData) {
      history.push("/thanks")
    }
  },[addClientData])

  if(addClientData) {
    return (
      <div class="thankyouDiv" style={{backgroundColor:"transparent", width:"hei"}}>
            <h1 class="thanksYouH">Thank You!</h1>
            <p class="thankYouP">One of our colleagues will get back in touch with you soon for the best offer!<br/>Have a great day!.</p>              
          </div>
    )
  }

    return(
      <div style={{display:"flex"}}>
        {/* <div style={{border:"2px solid red", display:"flex", flexDirection:"column"}}>
            <div style={{width:"200px", height:"200px", border:"2px solid green"}}>before</div>
            <div style={{width:"200px", height:"200px", border:"2px solid green"}}>before</div>
            <div style={{width:"200px", height:"200px", border:"2px solid green"}}>before</div>
            <div style={{width:"200px", height:"200px", border:"2px solid green"}}>before</div>
            <div style={{width:"200px", height:"200px", border:"2px solid green"}}>before</div>
        </div> */}
      <div
    data-mk-stretch-content="true"
    className="wpb_row vc_row vc_row-fluid jupiter-donut- mk-fullwidth-false  attched-false     js-master-row  mk-grid"
  >
    <div style={{width:"100%"}} className="vc_col-sm-10 wpb_column column_container  jupiter-donut- _ jupiter-donut-height-full">
      <h2
      style={{textAlign:"center", paddingTop:"50px", color:"#404040"}}  
        id="fancy-title-65"
        className="mk-fancy-title  simple-style jupiter-donut-  color-single"
      >
        <span>
          <h3 style={{fontWeight:"1000"}}>Are You Ready?</h3>
        </span>
      </h2>
      <div className="clearboth" />
      <style
        dangerouslySetInnerHTML={{
          __html: '#fancy-title-65{font-family:"Fjalla One"}'
        }}
      />
{/*       
      <div style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
      <img style={{margin:"30px 0", width:"70%"}} src="https://i.ibb.co/fptsZqV/Screenshot-2024-05-26-at-12-30-00-PM.png" />
      </div> */}
      <div style={{ textAlign:"center", fontSize:"4.5vmin", fontWeight:"500", width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>

          <h3>Do it now with Body Fit EMS and show the result from the third session</h3>
</div>

<div style={{width:"100%", borderBottom:"1px solid gray"}} />
{/* <div style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}}>

<div style={{width:"50%"}}>
<div onClick={() => setGoal("LOSE WEIGHT")}  style={{ cursor:"pointer", border: goal == "LOSE WEIGHT" ? "2px solid black" : "0px",background:"#1CF2CA", fontWeight:"1000", color:"black", width:"95%", minWidth:"120px", padding:"15px", height:"70px", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center", margin:"20px 5px"}}>
LOSE WEIGHT
</div>
<div onClick={() => setGoal("BODY TONING ")}  style={{ cursor:"pointer", border: goal == "BODY TONING " ? "2px solid black" : "0px",background:"#1CF2CA", fontWeight:"1000", color:"black", width:"95%", minWidth:"120px", padding:"15px", height:"70px", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center", margin:"20px 5px"}}>

BODY TONING 
</div>
</div>
<div style={{width:"50%"}}>
<div onClick={() => setGoal("REDUCE CELLULITE")}  style={{ cursor:"pointer", border: goal == "REDUCE CELLULITE" ? "2px solid black" : "0px",background:"#1CF2CA", fontWeight:"1000", color:"black", width:"95%", minWidth:"120px", padding:"15px", height:"70px", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center", margin:"20px 5px"}}>

REDUCE CELLULITE
</div>
<div onClick={() => setGoal("BUILD MUSCLES")}  style={{ cursor:"pointer", border: goal == "BUILD MUSCLES" ? "2px solid black" : "0px",background:"#1CF2CA", fontWeight:"1000", color:"black", width:"95%", minWidth:"120px", padding:"15px", height:"70px", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center", margin:"20px 5px"}}>

BUILD MUSCLES
</div>

</div>
</div> */}
{/* 
<div style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>

<h1
id="fancy-title-65"
className="mk-fancy-title  simple-style jupiter-donut-  color-single"
>
<span>
<h1>START YOUR FITNESS JOURNEY</h1>
</span>
</h1>
</div> */}

    
                <div style={{ width:"100%", marginTop:"50px"}} className="nf-form-content ">
                      <div
                      style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", flexWrap:"wrap"}}
                        id="nf-field-41_1-container"
                        className="nf-field-container textbox-container  label-hidden"
                      >
                    
                        <div style={{width:"97%", minWidth:"250px", margin:"10px 0"}} className="nf-field">
                          <div
                            id="nf-field-41_1-wrap"
                            className="field-wrap textbox-wrap"
                            data-field-id="41_1"
                          >
                            <div>Full Name <span style={{color:"red"}}>*</span></div>
                            <div className="nf-field-element">
                              <input
                                type="text"
                                defaultValue=""
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="form-input"
                                placeholder="Type your full name"
                                id="nf-field-41_1"
                                name="nf-field-41_1"
                                aria-invalid="false"
                                aria-describedby="nf-error-41_1"
                                aria-labelledby="nf-label-field-41_1"
                                required=""
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{width:"97%", minWidth:"250px", margin:"10px 0"}} className="nf-field">
                          <div
                            id="nf-field-43_1-wrap"
                            className="field-wrap phone-wrap textbox-wrap"
                            data-field-id="43_1"
                          >
                            <div>Phone Number <span style={{color:"red"}}>*</span></div>
                            <div className="nf-field-element">
                            <PhoneNumber /> 
                              {/* <input
                              value={mobile}
                              onChange={(e) => setMobile(e.target.value1)}
                                type="tel"
                                defaultValue=""
                                className="ninja-forms-field get-fit-now-phone nf-element"
                                id="nf-field-43_1"
                                name="phone"
                                autoComplete="tel"
                                placeholder="Phone"
                                aria-invalid="false"
                                aria-describedby="nf-error-43_1"
                                aria-labelledby="nf-label-field-43_1"
                                required=""
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div style={{width:"97%", minWidth:"250px", margin:"10px 0"}} className="nf-field">
                          <div
                            id="nf-field-41_1-wrap"
                            className="field-wrap textbox-wrap"
                            data-field-id="41_1"
                          >
                            <div>E-mail <span style={{color:"red"}}>*</span></div>
                            <div className="nf-field-element">
                            <div>
      <input
        type="text"
        value={email}
        onChange={handleChange}
        className={`form-input ${isValid ? '' : 'invalid'}`}
        placeholder="ex. myname@example.com"
        id="nf-field-41_1"
        name="nf-field-41_1"
        aria-invalid={!isValid}
        aria-describedby="nf-error-41_1"
        aria-labelledby="nf-label-field-41_1"
        required
      />
      {!isValid && <div id="nf-error-41_1" style={{color:"#F10000"}}>Please enter a valid email address.</div>}
    </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                </div>
                <h2
      style={{textAlign:"center", paddingTop:"20px", color:"#404040"}}  
        id="fancy-title-65"
        className="mk-fancy-title  simple-style jupiter-donut-  color-single"
      >
        <span>
          <h5 style={{fontWeight:"1000"}}>Push harded than yesterday if you want a better tomorrow</h5>
        </span>
      </h2>


<div style={{display:"flex", flexWrap:"wrap", justifyContent:"flex-start"}}>

<div style={{width:"70%", minWidth:"300px"}}>

      <div>Session Date/Time</div>
      <DateTimePicker 
      // hourPlaceholder="h"
      // minutePlaceholder="m"
      // monthPlaceholder="mm"
      // yearPlaceholder="yyyy"
      format="y-MM-dd h:mm a" amPmAriaLabel="Select AM/PM" value={selectedTime} onChange={(value) => setSelectedTime(value)} className="timePicker"/>
</div>


<div style={{width:"30%", minWidth:"200px"}}>
<div>
                  Location
                </div>
                <Dropdown
                  options={locationOptions()}
                  onChange={(choice) =>
                    setLocation(choice.value)
                  }
                  value={location}
                  controlClassName="timePicker"
                  
                />  
</div>

</div>
      <div
                        id="nf-field-45_1-container"
                        style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", flexWrap:"wrap"}}
                        className="nf-field-container listselect-container  label-above  list-container"
                      >
                        <div style={{width:"97%", marginTop:"30px", minWidth:"250px", margin:"10px 0"}} className="nf-field">
                          <div
                            id="nf-field-41_1-wrap"
                            className="field-wrap textbox-wrap"
                            data-field-id="41_1"
                          >
                            <div>if you have medical history please let us know</div>
                            <div className="nf-field-element">

                            <textarea
               onChange={(e) => setNotes(e.target.value)}
               value={notes}
                type="text"
                className="auth-input textarea-input"
                                id="nf-field-41_1"
                                name="nf-field-41_1"
                                aria-invalid="false"
                                aria-describedby="nf-error-41_1"
                                aria-labelledby="nf-label-field-41_1"
                                required=""

              />

                              
                            </div>
                          </div>
                        </div>
                        
                      </div>  
                      
      
                {/* <div style={{ display:"flex", padding:"30px 0", alignItems:"center", justifyContent:"center"}}>


<h1
id="fancy-title-65"
className="mk-fancy-title  simple-style jupiter-donut-  color-single"
>
<span>
<h1>RECOMMEND 5 OF YOUR FRIENDS TO TRY EMS AND GET FREE SESSION</h1>
</span>
</h1>
</div> */}
{/* <div>
                        {users.map((x, index) => (
                        <div
                        key={x}
                        style={{display:"flex", alignItems:"center"}}
                        id="nf-field-41_1-container"
                        className="nf-field-container textbox-container  label-hidden "
                        >

                        <div style={{ width:"20%", minWidth:"50px", fontWeight:"1000", fontSize:"24px", color:"black"}} className="nf-field">
                        <div
                        id="nf-field-41_1-wrap"
                        className="field-wrap textbox-wrap"
                        data-field-id="41_1"
                        >
                        <div className="nf-field-element">
                        {x.ref}
                        </div>
                        </div>
                        </div>
                        <div style={{display:"flex", width:"80%", flexWrap:"wrap"}}>
                        <div style={{ width:"50%", minWidth:"200px", margin:"0 10px"}} className="nf-field">
                        <div
                        id="nf-field-41_1-wrap"
                        className="field-wrap textbox-wrap"
                        data-field-id="41_1"
                        >
                        <div className="nf-field-element">
                        <input
                        type="text"
                        defaultValue=""
                        value={x.name}
                        onChange={(e) => handleNameChange(index, e.target.value)}
                        className="form-input"
                        placeholder="Name"
                        id="nf-field-41_1"
                        name="nf-field-41_1"
                        aria-invalid="false"
                        aria-describedby="nf-error-41_1"
                        aria-labelledby="nf-label-field-41_1"
                        required=""
                        style={{background:"#1CF2CA", color:"black"}}
                        />
                        </div>
                        </div>
                        </div>
                        <div style={{ width:"30%", minWidth:"200px", margin:"0 10px"}} className="nf-field">
                        <div
                        id="nf-field-43_1-wrap"
                        className="field-wrap phone-wrap textbox-wrap"
                        data-field-id="43_1"
                        >
                        <div className="nf-field-element">
                        <PhoneNumber
                        value={x.number}
                        sub={true}
                        onblur={(value) => handleNumberChange(index, value)}
                        style={{background:"#1CF2CA", color:"black"}}
                        /> 
                        <input
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value1)}
                        type="tel"
                        defaultValue=""
                        className="ninja-forms-field get-fit-now-phone nf-element"
                        id="nf-field-43_1"
                        name="phone"
                        autoComplete="tel"
                        placeholder="Phone"
                        aria-invalid="false"
                        aria-describedby="nf-error-43_1"
                        aria-labelledby="nf-label-field-43_1"
                        required=""
                        />
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        ))}
</div> */}
                      <div
                        id="nf-field-44_1-container"
                        className="nf-field-container submit-container  label-above  textbox-container"
                      >
                        <div className="nf-before-field">
                          <nf-section></nf-section>
                        </div>
                        <div className="nf-field">
                          <div
                            id="nf-field-44_1-wrap"
                            className="field-wrap submit-wrap textbox-wrap"
                            data-field-id="44_1"
                          >
                            <div className="nf-field-label" />
                            <div style={{width:"100%", paddingTop:"50px", display:"flex", justifyContent:"center", alignItems:"center"}}>
  <div
  style={{
  width:"30%",
  height:"30px",
  borderRadius:"5px",
  color:"black",     
  fontWeight:"1000",
  minWidth:"250px",
  background: "rgb(23, 189, 91)",
}}
    className={`${ name && email && phoneNumber && phoneIsValid ? "auth-button addUser": "auth-button addUser disable-button"}`}
    onClick={name && email && phoneNumber && phoneIsValid ? inviteUser : null }
  >
    
    {"Submit"}

  </div>
</div>
                            <div className="nf-error-wrap" />
                          </div>
                        </div>
                        <div className="nf-after-field">
                          <nf-section>
                            <div className="nf-input-limit" />
                            <div
                              id="nf-error-44_1"
                              className="nf-error-wrap nf-error"
                              role="alert"
                            />
                          </nf-section>
                        </div>
                      </div>
      </div>
      </div>
        {/* <div style={{border:"2px solid red", display:"flex", flexDirection:"column"}}>
        <div style={{width:"200px", height:"200px", border:"2px solid green"}}>after</div>
        <div style={{width:"200px", height:"200px", border:"2px solid green"}}>after</div>
        <div style={{width:"200px", height:"200px", border:"2px solid green"}}>after</div>
        <div style={{width:"200px", height:"200px", border:"2px solid green"}}>after</div>
        <div style={{width:"200px", height:"200px", border:"2px solid green"}}>after</div>
        <div style={{width:"200px", height:"200px", border:"2px solid green"}}>after</div>

        </div> */}
      </div>
                  
    )
}

export default UserForm