import React from "react"

const AvatarModal = (props) => {

    const {
takeAPhotoSelected,
changeTakeAPhotoSelected,
webcamURI,
changeWebcamURI,
imageUploaded,
changeImageUploaded,
imagePreviewAvailable,
changeImagePreviewAvailable,
handleImageUploaded
    } = props



    return (
        
                              <div
                              >
                               
                              </div>
                            
    )
}

export default AvatarModal