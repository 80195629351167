import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmDeleteModal = (props) => {
    const {
        deleteModal,
        setDeleteModal,
        userType,
        deleteClientFunc,
        deleteUserFunc
        } = props


  return(
<Modal
        isOpen={deleteModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"40px",
            borderRadius: "none",
            width: "auto",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => setDeleteModal(null)}
              />
               <div className="modal password-modal">
              
          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Delete account</h1>
          <h1 className="header" style={{width:"100%", textTransform:"capitalize" , fontWeight:"bolder", fontSize:"17px"}}>Are you sure you want to delete this account</h1>
            
            <div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>

                <div
                
                className="auth-button addUser confirmDelete"
                onClick={() => {              
                setDeleteModal(null)
                }}
                >
                Cancel
                </div> 

                <div
                
                    className="auth-button addUser confirmDelete deleteButton"
                    onClick={userType == "client" ? () => {
                        deleteClientFunc(deleteModal)
                        setDeleteModal(null)   
                    }: () => {
                        deleteUserFunc(deleteModal)
                        setDeleteModal(null)
                    
                    }}
                >

                Delete

                </div>

            </div>
        </div>
      </Modal>
  )}

  export default ConfirmDeleteModal
       