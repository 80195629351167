import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, FormFeedback, Label, Input } from "reactstrap";

import ACTION_CONFIRM_NEW_PASSWORD from "../../../../actions/user/Login/ConfirmNewPassword/ACTION_CONFIRM_NEW_PASSWORD";
import ACTION_CONFIRM_NEW_PASSWORD_RESET from "../../../../actions/user/Login/ConfirmNewPassword/ACTION_CONFIRM_NEW_PASSWORD_RESET";
import ACTION_CONFIRM_NEW_PASSWORD_NOT_INVALID from "../../../../actions/user/Login/ConfirmNewPassword/Invalid/ACTION_CONFIRM_NEW_PASSWORD_NOT_INVALID";
import ACTION_CONFIRM_NEW_PASSWORD_VALID from "../../../../actions/user/Login/ConfirmNewPassword/Valid/ACTION_CONFIRM_NEW_PASSWORD_VALID";
import ACTION_CONFIRM_NEW_PASSWORD_INVALID from "../../../../actions/user/Login/ConfirmNewPassword/Invalid/ACTION_CONFIRM_NEW_PASSWORD_INVALID";
import ACTION_CONFIRM_NEW_PASSWORD_NOT_VALID from "../../../../actions/user/Login/ConfirmNewPassword/Valid/ACTION_CONFIRM_NEW_PASSWORD_NOT_VALID";
import show from "../../../../assests/icons/show.svg"
import hide from "../../../../assests/icons/hide.svg"

const UserConfirmNewPassword = (props) => {
  const dispatch = useDispatch();

  // User Confirm New Password States
  const newPassword = useSelector(
    (state) => state.newPassword.new_password
  );
  const newPasswordValid = useSelector(
    (state) => state.newPasswordValid.new_password_valid
  );
  const confirmNewPassword = useSelector(
    (state) => state.confirmNewPassword.confirm_new_password
  );
  const confirmNewPasswordInvalid = useSelector(
    (state) =>
      state.confirmNewPasswordInvalid.confirm_new_password_invalid
  );
  const confirmNewPasswordValid = useSelector(
    (state) =>
      state.confirmNewPasswordValid.confirm_new_password_valid
  );

  useEffect(() => {
    if (confirmNewPassword === newPassword) {
      dispatch(ACTION_CONFIRM_NEW_PASSWORD_NOT_INVALID());
      dispatch(ACTION_CONFIRM_NEW_PASSWORD_VALID());
    } else {
      dispatch(ACTION_CONFIRM_NEW_PASSWORD_INVALID());
      dispatch(ACTION_CONFIRM_NEW_PASSWORD_NOT_VALID());
    }
  }, [dispatch, confirmNewPassword, newPassword]);

  const handleConfirmNewPassword = (e) => {
    dispatch(ACTION_CONFIRM_NEW_PASSWORD(e.currentTarget.value.trim()));
  };

  const confirmNewPasswordTyping = (e) => {
    dispatch(ACTION_CONFIRM_NEW_PASSWORD_RESET());

    if (newPassword) {
      if (newPasswordValid) {
        if (e.currentTarget.value === newPassword) {
          dispatch(
            ACTION_CONFIRM_NEW_PASSWORD(e.currentTarget.value.trim())
          );
          dispatch(ACTION_CONFIRM_NEW_PASSWORD_NOT_INVALID());
          dispatch(ACTION_CONFIRM_NEW_PASSWORD_VALID());
        }
      }
    }
  };
  const [type, setType] = useState(true)

  return (
    <FormGroup>
      <Label for="loginPassword">
        <div className="auth-label"> <div>Confirm {!props.confirm && "new"} Password</div> <div>
        {
      confirmNewPassword && confirmNewPassword !== newPassword ? (
        <div className="auth-error">
        Passwords must match.
      </div>
      ) : null
      }
          </div> </div>
      </Label>
      <Input
        type={type ? "password" : "text"}
        name="createAccountConfirmPassword"
        defaultValue={confirmNewPassword}
        maxLength={128}
        placeholder="Re-enter new password"
        className="auth-input"
        onChange={confirmNewPasswordTyping}
        onBlur={handleConfirmNewPassword}
        invalid={
          confirmNewPassword === ""
            ? false
            : confirmNewPasswordInvalid
            ? true
            : false
        }
        valid={
          confirmNewPassword === ""
            ? false
            : confirmNewPasswordValid
            ? true
            : false
        }
      />      
      <span className="errspan" onClick={() => setType(!type)}><img src={!type ? show : hide} /></span>

    </FormGroup>
  );
};

export default UserConfirmNewPassword;
