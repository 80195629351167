import { gql } from "apollo-boost";

const updateUserInvalidateTokensMutation = gql`
  mutation {
    updateUserInvalidateTokens {
      _id
    }
  }
`;

export default updateUserInvalidateTokensMutation;
