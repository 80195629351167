import React, { useEffect, useState } from "react";
import {  Link, useLocation, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";

import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

import loginQuery from "../../../graphql/queries/user/loginQuery";

import ACTION_LOGIN_IS_NOT_ACTIVE from "../../../actions/user/Login/ACTION_LOGIN_IS_NOT_ACTIVE";
import ACTION_LOGIN_IS_ACTIVE from "../../../actions/user/Login/ACTION_LOGIN_IS_ACTIVE";
import ACTION_LOGIN_EMAIL_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_TEMPO_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_INVALID";
import ACTION_LOGIN_PASSWORD_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_INVALID";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import ACTION_LOGIN_TEMPO_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_TEMPORARY_DUMMY_TOKEN_RESET from "../../../actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN_RESET";


import LoginEmail from "./LoginEmail/LoginEmail";
import LoginPassword from "./LoginPassword/LoginPassword";
import UserNewPassword from "./NewPassword/UserNewPassword";
import UserConfirmNewPassword from "./NewPassword/UserConfirmNewPassword";
import updateUserPasswordMutation from "../../../graphql/mutations/user/updateUserPasswordMutation"


import "./Auth.scss"
import 'react-dropdown/style.css';

import ForgetPassword from "./ForgetPassword";
import resetPasswordMutation from "../../../graphql/mutations/user/resetPasswordMutation";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";


import illustration from "../../../assests/images/illustration.png"
import logo from "../../../assests/images/Logo.png"
import google from "../../../assests/icons/google.png"
import ACTION_LOGIN_EMAIL from "../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL";
import TextInput from "./TextInput";
import Dropdown from "react-dropdown";

import arrowUp from "../../../assests/images/arrowUp.svg"
import arrowDownDark from "../../../assests/images/arrowDownDark.svg"

const Login = ({user}) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  const loginEmail = useSelector((state) => state.loginEmail.login_email);
  const loginEmailInvalid = useSelector(
    (state) => state.loginEmailInvalid.login_email_invalid
  );
  const loginTempoInvalid = useSelector(
    state => state.loginTempoInvalid.login_tempo_invalid
  );
  const loginPassword = useSelector(
    (state) => state.loginPassword.login_password
  );
  const loginPasswordInvalid = useSelector(
    (state) => state.loginPasswordInvalid.login_password_invalid
  );
  const registeredUserFound = useSelector(
    (state) => state.registeredUserFound.registered_user_found
  );

  const confirmNewPassword = useSelector(
    (state) => state.confirmNewPassword.confirm_new_password
  );
  const newPasswordValid = useSelector(
    (state) => state.newPasswordValid.new_password_valid
  );
  const confirmNewPasswordValid = useSelector(
    (state) =>
      state.confirmNewPasswordValid.confirm_new_password_valid
  );
  const userAuthenticated = useSelector(
    (state) => state.userAuthenticated.user_authenticated
  );

  const temporaryDummyToken = useSelector((state) => state.temporaryDummyToken.temporary_dummy_token);

  const resetUser = () => { 
    Cookies.remove("temporary-dummy-token")
    dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET())

  }
  const handleUserLogin = () => {

    if (registeredUserFound) {
      if (loginEmailInvalid) {
        dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
      }

      if (loginPasswordInvalid) {
        dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
      } 
      if(loginTempoInvalid) {
        dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
      }
    }

  };

  const handleUserLoginError = () => {
    if (error) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
      console.log(error.message)
      if (error.message) {
        if (error?.message?.includes("email")) {
          dispatch(ACTION_LOGIN_EMAIL_INVALID());
          dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
          dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID());
        } else if (error?.message?.includes("temporarily")) {
          dispatch(ACTION_LOGIN_TEMPO_INVALID());
          dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
          dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
        } else {
          dispatch(ACTION_LOGIN_PASSWORD_INVALID());
          dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
          dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID());

        }
      }
    }
  };

  const [loginUser, { error }] = useLazyQuery(loginQuery, {
    fetchPolicy: "no-cache",
    onCompleted: handleUserLogin,
    onError: handleUserLoginError,
  });


  const handleLoginClick = (e) => {
    e && e.preventDefault()
    loginUser({
      variables: {
        email: loginEmail,
        password: loginPassword,
      },
    });
  };




  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);


  useEffect(() => {
    dispatch(ACTION_LOGIN_IS_ACTIVE());
    return () => {
      dispatch(ACTION_LOGIN_IS_NOT_ACTIVE());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
      dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
      dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
    };
  }, [dispatch]);
 

const [
  updateUserPassword,
  { loading: updateUserPasswordLoading, data: updateUserPasswordData },
] = useMutation(updateUserPasswordMutation)


const handleChangePasswordClick = (e) => {
  e && e.preventDefault()
// if(onBoardingActive) {
//    if( orgName && industry && timeZone && language && currency) {
//   dispatch(ACTION_LOADING_SPINNER_ACTIVE())
//   updateUserPassword({
//     variables: { password: confirmNewPassword },
//   })
// }
// } else {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  updateUserPassword({
    variables: { password: confirmNewPassword },
  })
// }
 

};

useEffect(() => {
  if (!updateUserPasswordLoading) {
    if (updateUserPasswordData) {
      loginUser({
        variables: {
          email: loginEmail,
          password: confirmNewPassword,
        },
      });
  }
}
}, [
  updateUserPasswordLoading,
  confirmNewPassword,
  loginUser,
  updateUserPasswordData,
  dispatch
]);


// useEffect(() => {
//   if (!addOrganizationLoading) {
//     if (addOrganizationData) {
//       console.log("token reset")
//       dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET())
//       loginUser({
//         variables: {
//           email: loginEmail,
//           password: confirmNewPassword,
//         },
//       });     
//     }
//   }
// }, [
//   addOrganizationLoading,
//   loginUser,
//   addOrganizationData,
//   dispatch
// ]);



// reset password

const [
  resetPassword,
  { data: resetPasswordData },
] = useMutation(resetPasswordMutation)
 

const [resetPasswordModal, setResetPasswordModal] = useState(false)
const [userEmail, setUserEmail] = useState("")

const forgetPassword = () => {
if(loginEmail) {
   dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  resetPassword({
    variables:{
      email: loginEmail
    }
  })
}
 
}

useEffect(() => {
  if(resetPasswordData) {
    setForgotPasswordDone(true)
    dispatch(ACTION_LOADING_SPINNER_RESET())
  }
},[resetPasswordData, dispatch])

useEffect(() => {
if(userAuthenticated) {
  history.push("/admin/campaign")
  dispatch(ACTION_LOADING_SPINNER_RESET())
} else {
  dispatch(ACTION_LOADING_SPINNER_RESET())
}
},[userAuthenticated])

const [checked, setChecked] = useState(false)

const [forgotPassword, setForgotPassword] = useState(false)
const [forgotPasswordDone, setForgotPasswordDone] = useState(false)
const [onBoardingActive, setOnBoardingActive] = useState(false)

const handleOnBoargingFunc = () => {
  setOnBoardingActive(true)
}

// get started information

const [orgName, setOrgName] = useState("")

const [orgError, setOrgError] = useState("")

const industries = ['test1', "test2", "test3", "test4", "test5"]
const [industry, setIndustry] = useState("")
const timeZones= ['test1', "test2", "test3", "test4", "test5"] 
const [timeZone, setTimeZone] = useState("")
const languages= ['test1', "test2", "test3", "test4", "test5"] 
const [language, setLanguage] = useState("")
const currencies = ['test1', "test2", "test3", "test4", "test5"] 
const [currency, setCurrency] = useState("")

  return (
    <div className="signinPageContainer">
      <div className=" signinPageContainer singinPageContent">
      <ForgetPassword 
        resetPasswordModal={resetPasswordModal}
        setResetPasswordModal={setResetPasswordModal}
        setUserEmail={setUserEmail}
        userEmail={userEmail}
        forgetPassword={forgetPassword}
      />
      <div className="signinContainer">
        <div className="logoContainer">
          <div />
        </div>
      {forgotPassword ? 
      forgotPasswordDone ? 
        <div className="signinContent">
                  <div className="signinBoxContainer">

        <div className="auth-header">
            Check your email
        </div>
        <div>
                        <div className="doneText">
                        We’ve sent password reset instruction to
                        <span style={{fontWeight:"700"}}>{loginEmail ? loginEmail : "Your email"}</span>
                        </div>
                <div className="doneText">
                If it doesn’t arrive soon, check your spam folder or
    send the email again.
                </div>
                <div className="doneText">Need help? <span style={{fontWeight:"700"}}>Contact support.</span></div>
                <div className="auth-button hoverButton" onClick={() => {
                  setForgotPassword(false)
                  setForgotPasswordDone(false)
                  dispatch(ACTION_LOGIN_EMAIL(""))
                }}>Back to Home</div>
                </div>
          </div>
            </div> : (
              <div className="signinContent">
                <div className="signinBoxContainer" >
              <div className="auth-header">
                  Forgot Password
              </div>
              <div>
                              <div className="doneText">
                              Enter the email associated with your account and we'll send instructions to reset your password.
                              </div>
                      <div className="doneText"><LoginEmail /></div>
                      <div className="auth-button hoverButton"
                      style={{
                        color: temporaryDummyToken
                        ? newPasswordValid && confirmNewPasswordValid
                          ? "rgb(255, 255, 255)"
                          : "white"
                        : loginEmail
                          ? "rgb(255, 255, 255)"
                          : "white",
                        pointerEvents: temporaryDummyToken
                          ? newPasswordValid && confirmNewPasswordValid
                            ? "auto"
                            : "none"
                          : loginEmail
                          ? "auto"
                          : "none",
                        background: temporaryDummyToken
                          ? newPasswordValid && confirmNewPasswordValid
                            ? "#B20312"
                            : "#b2031280"
                          : loginEmail
                          ? "#B20312"
                          : "#b2031280",
                        transition: "background 0.5s ease, color 0.5s ease",
                        width:"100%",
                        minWidth:"140px",
                        marginTop:"1.8rem"
                    }} 
                      onClick={forgetPassword}>Submit</div>
                      </div>
                      </div>
                  </div>
            )
      : ( <> <div className="signinContent">
        <div className="signinBoxContainer">
        {onBoardingActive ? (
        <>
        <div className="auth-header">
        Get started
        </div>
        <div className="doneText" style={{margin:"0px 0px 24px 0px"}}>
        Enter information about your organization so that we can personalize our platform for you.
        </div>
        <div>
          <div className="auth-group">
          <TextInput 
            type="orgName"
            label="Name of your ORG"
            value={orgName}
            setValue={setOrgName}
            placeHolder="Enter org name"
            error={orgError}
            setError={setOrgError} 
          />
          </div>

          <div className="auth-group">
            <div className="auth-label">Industry</div>
          <Dropdown
                  options={industries}
                  controlClassName="auth-input" 
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}            
                  placeholder={"Select industry of your ORG"}
                  value={industry}
                  onChange={(e) => {
                   setIndustry(e.value)
                  }}
                />
          </div>

          <div className="auth-group">
          <div className="auth-label">timeZone</div>
          <Dropdown
                  options={timeZones}
                  controlClassName="auth-input"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your timezone"}
                  value={timeZone}
                  onChange={(e) => {
                    setTimeZone(e.value)
                  }}
                  
                />
          </div>
          <div className="auth-group">
          <div className="auth-label">language</div>
          <Dropdown
                  options={languages}
                  controlClassName="auth-input" 
                  menuClassName="underMenu"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your default language"}
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.value)
                  }}
                  
                />
          </div>
          <div className="auth-group">
          <div className="auth-label">currency</div>
          <Dropdown
                  options={currencies}
                  controlClassName="auth-input" 
                  menuClassName="underMenu"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your currency"}
                  value={currency}
                  onChange={(e) => {
                    setCurrency(e.value)
                  }}
                  
                />
          </div>

          <div>
            <div
             onClick={
              handleChangePasswordClick
          } 
            style={{
              color: onBoardingActive
              ? orgName && industry && timeZone && language && currency
                ? "rgb(255, 255, 255)"
                : "white"
              : loginEmail && loginPassword
                ? "rgb(255, 255, 255)"
                : "white",
              pointerEvents: onBoardingActive
                ? orgName && industry && timeZone && language && currency
                  ? "auto"
                  : "none"
                : loginEmail && loginPassword
                ? "auto"
                : "none",
              background: onBoardingActive
                ? orgName && industry && timeZone && language && currency
                  ? "#B20312"
                  : "#b2031280"
                : loginEmail && loginPassword
                ? "#B20312"
                : "#b2031280",
              transition: "background 0.5s ease, color 0.5s ease",
              width:"100%",
              minWidth:"140px",
              marginTop:"1.8rem"
          }} 
          className="auth-button hoverButton">
            Let's go!
            </div>          
          </div>
        </div>
        </>
        ) : (
          <>
        <div className="auth-header">
        {temporaryDummyToken ? "Create new password" : "Sign In"}
        </div>
        {temporaryDummyToken && <div className="doneText" style={{margin:"0px 0px 24px 0px"}}>
        Your new password must be different from previous used passwords.
        </div>}
        <div>
          <div className="auth-group">
          { temporaryDummyToken ? (
            <UserNewPassword
            handleChangePasswordClick={handleChangePasswordClick}
          /> ) : (
          <LoginEmail /> ) }
          </div>

          <div className="auth-group">
          { temporaryDummyToken ? (
            <UserConfirmNewPassword
            handleChangePasswordClick={handleChangePasswordClick}
          /> 
          ) : (
          <LoginPassword handleLoginClick={handleLoginClick } />
          )}
          
          </div>
          { !temporaryDummyToken ? ( <div className="bottom-group">
          <div className="rememberMeContainer"><label>
            <input type='checkbox' onChange={() => setChecked(!checked)} checked={checked}/>
            <span className={`${checked && "spanChecked"}`}></span>
            Remember me
          </label></div>
            <div className="link" onClick={() => setForgotPassword(true)}>Forgot password?</div>
          </div> ) : (
            <div className="bottom-group"> 
            <div onClick={resetUser} className="link">
            Back
          </div>
          </div>
          )}

          <div>
            <div
             onClick={
              temporaryDummyToken ? 
              handleChangePasswordClick 
                //? handleChangePasswordClick
                : handleLoginClick
          } 
            style={{
              color: temporaryDummyToken
              ? newPasswordValid && confirmNewPasswordValid
                ? "rgb(255, 255, 255)"
                : "white"
              : loginEmail && loginPassword
                ? "rgb(255, 255, 255)"
                : "white",
              pointerEvents: temporaryDummyToken
                ? newPasswordValid && confirmNewPasswordValid
                  ? "auto"
                  : "none"
                : loginEmail && loginPassword
                ? "auto"
                : "none",
              background: temporaryDummyToken
                ? newPasswordValid && confirmNewPasswordValid
                  ? "#B20312"
                  : "#b2031280"
                : loginEmail && loginPassword
                ? "#B20312"
                : "#b2031280",
              transition: "background 0.5s ease, color 0.5s ease",
              width:"100%",
              minWidth:"140px",
              marginTop:"1.8rem"
          }} 
          className="auth-button hoverButton">
    {temporaryDummyToken ? "Change Password" : "Sign in"}
            </div>
            
          </div>
        </div> </>)}
        </div>
        </div>
        </>
        )}

        </div>
        <div className="rightSignInContainer">
        <div className="loginRightContainer">
          <img src={illustration} width={560} />
      <div className="loginHeader">
      Bring your Organization <br/>
      into the <span style={{fontWeight:"bold"}}>Digital Era.</span>
          </div>
          </div>
      </div>
        </div>
  </div>
  );
};

export default Login;
