import React, { useState } from "react"
import BookModal from "../../reusable/bookModal"
import {useMutation} from "@apollo/client"
import addClientMutation from "../../../graphql/mutations/client/addClientMutation"
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import ACTION_PHONE_NUMBER_RESET from "../../../actions/GuestCheckoutForm/PhoneNumber/ACTION_PHONE_NUMBER_RESET"



export const BookButton = (props) => {
   
    const dispatch = useDispatch()
    const history = useHistory()
    const [bookModal, setBookModal] = useState(false)
    const [clientModal, setClientModal] = useState(false)
    const [clientName, setClientName] = useState("")
    const [clientEmail, setClientEmail] = useState("")
    const [clientMobile, setClientMobile] = useState("")
    const [clientLocation, setClientLocation] = useState("")
    const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const phoneNumber = useSelector((state) => state.phoneNumber.phone_number);


    const [
      addClient,
      { loading:addClientLoading, data:addClientData },
    ] = useMutation(addClientMutation);
  
    const inviteUser = () => {
        console.log("just invited him")
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setClientModal(null)
      addClient({
        variables:{
            name: clientName,
            phoneNumber: phoneNumber,
            location: clientLocation,
            type: "website",
        }
      })
      .then(() => {
        setClientName("")
        setClientEmail("")
        setClientMobile("")
        dispatch(ACTION_PHONE_NUMBER_RESET())
        dispatch(ACTION_LOADING_SPINNER_RESET())      
        })
      

    }


    if(clientModal) {
        return (
            <BookModal
            clientModal={clientModal}
            setClientModal={setClientModal}
            setClientName={setClientName}
            clientName={clientName}
            setClientEmail={setClientEmail}
            clientEmail={clientEmail}
            inviteUser={inviteUser}
            emailAlreadyRegistered={emailAlreadyRegistered}
            invalidEmail={invalidEmail}
            setEmailAlreadyRegistered={setEmailAlreadyRegistered}
            setClientMobile={setClientMobile}
            clientMobile={clientMobile}
            setClientLocation={setClientLocation}
            clientLocation={clientLocation}
            />
        )
    }
    if(props.master) {
        return (
           <div onClick={() => {
  history.push("/form-ems-egypt")
}} className="vc_col-sm-4 wpb_column column_container  jupiter-donut- _ jupiter-donut-height-full">
                              <div
                                id="mk-button-91"
                                className="mk-button-container _ jupiter-donut-relative    jupiter-donut-inline-block jupiter-donut-left book pum-trigger"
                                style={{ cursor: "pointer" }}
                              >
                                <a
                                  target="_self"
                                  className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                                >
                                  <i className="mk-button--icon" />
                                  <span className="mk-button--text">
                                    BOOK A TRIAL SESSION
                                  </span>
                                </a>
                              </div>
                            </div> 
        )
    }

    if(props.type == "aboutus") {
      return(
        <div                     onClick={() => {
  history.push("/form-ems-egypt")
}}

        style={{ cursor: "pointer" }}
className="vc_col-sm-12 wpb_column column_container  jupiter-donut- _ jupiter-donut-height-full">
                        <div
                          id="mk-button-32"
                          className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky"
                        >
                          <a
                            className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                          >
                            <span className="mk-button--text">
                              BOOK A TRIAL SESSION
                            </span>
                          </a>
                        </div>
                      </div>
      )
    }
    if(props.type == "goals") {
      return (
        <div
        onClick={() => {
  history.push("/form-ems-egypt")
}}
        style={{ cursor: "pointer" }}
        id="mk-button-21"
        className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky-blog"
      >
        <a
          target="_self"
          className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
        >
          <span className="mk-button--text">
            BOOK A TRIAL SESSION
          </span>
        </a>
      </div>
      )
    }
    if(props.type == "goaltwo") {
      return (
        <div
        
                onClick={() => {
  history.push("/form-ems-egypt")
}}
                style={{ cursor: "pointer" }}
                id="mk-button-19"
                className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky-blog"
              >
                <a
                  target="_self"
                  className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                >
                  <span className="mk-button--text">
                    BOOK A TRIAL SESSION
                  </span>
                </a>
        </div>
      )
    } if(props.type == "goalthree") {
      return(
        <div
        onClick={() => {
  history.push("/form-ems-egypt")
}}
        style={{ cursor: "pointer" }}
                          id="mk-button-25"
                          className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky-blog"
                        >
                          <a                            target="_self"
                            className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                          >
                            <span className="mk-button--text">
                              BOOK A TRIAL SESSION
                            </span>
                          </a>
                        </div>
      )
    }
    if(props.type == "goalfour") {
      return (
        <div
        onClick={() => {
  history.push("/form-ems-egypt")
}}
        style={{ cursor: "pointer" }}
                          id="mk-button-20"
                          className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky-blog"
                        >
                          <a
                            target="_self"
                            className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                          >
                            <span className="mk-button--text">
                              BOOK A TRIAL SESSION
                            </span>
                          </a>
                        </div>
      )
    } 
    if(props.type == "goalfive") {
      return(
            <div
            onClick={() => {
  history.push("/form-ems-egypt")
}}
            style={{ cursor: "pointer" }}
            id="mk-button-12"
            className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky-blog"
          >
            <a
              target="_self"
              className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
            >
              <span className="mk-button--text">
                BOOK A TRIAL SESSION
              </span>
            </a>
          </div>
      )
    } if(props.type == "goalsix") {
      return (
        <div
                          id="mk-button-2"
                          onClick={() => {
  history.push("/form-ems-egypt")
}}
            style={{ cursor: "pointer" }}
                          className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky-blog"
                        >
                          <a
                            target="_self"
                            className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                          >
                            <span className="mk-button--text">
                              BOOK A TRIAL SESSION
                            </span>
                          </a>
                        </div>
      )
    }
    if(props.type == "results") {
      return (
        <div

                data-mk-stretch-content="true"
                onClick={() => {
  history.push("/form-ems-egypt")
}}
                style={{ cursor: "pointer" }}
                className="wpb_row vc_row vc_row-fluid jupiter-donut- mk-fullwidth-false  attched-false     js-master-row  mk-grid"
              >
                <div className="vc_col-sm-12 wpb_column column_container  jupiter-donut- _ jupiter-donut-height-full">
                  <div
                    id="mk-button-65"
                    className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky"
                  >
                    <a
                      target="_self"
                      className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                    >
                      <span className="mk-button--text">
                        BOOK A TRIAL SESSION
                      </span>
                    </a>
                  </div>
                </div>
              </div>
      )
    } 
    if(props.type == "faq") {
      return (
        <div
        onClick={() => {
  history.push("/form-ems-egypt")
}}
        style={{ cursor: "pointer" }}
               data-mk-stretch-content="true"
                      className="wpb_row vc_row vc_row-fluid jupiter-donut- mk-fullwidth-false  attched-false     js-master-row  mk-grid"
                    >
                      <div className="vc_col-sm-12 wpb_column column_container  jupiter-donut- _ jupiter-donut-height-full">
                        <div
                          id="mk-button-11"
                          className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky"
                        >
                          <a
                            target="_self"
                            className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                          >
                            <span className="mk-button--text">
                              BOOK A TRIAL SESSION
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
      )
    }
    if(props.type == "pricing") {
      return (
        <div
        
        onClick={() => {
  history.push("/form-ems-egypt")
}}
        style={{ cursor: "pointer" }}
        data-mk-stretch-content="true"
        className="wpb_row vc_row vc_row-fluid jupiter-donut- mk-fullwidth-false  attched-false     js-master-row  mk-grid"
      >
        <div className="vc_col-sm-12 wpb_column column_container  jupiter-donut- _ jupiter-donut-height-full">
          <div
            id="mk-button-25"
            className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky"
          >
            <a
              target="_self"
              className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
            >
              <span className="mk-button--text">
                BOOK A TRIAL SESSION
              </span>
            </a>
          </div>
        </div>
      </div>
      )
    }
    if(props.type == "contact") {
      return (
        <div
        onClick={() => {
  history.push("/form-ems-egypt")
}}
        style={{ cursor: "pointer" }}
        data-mk-stretch-content="true"
        className="wpb_row vc_row vc_row-fluid jupiter-donut- mk-fullwidth-false  attched-false     js-master-row  mk-grid"
      >
        <div className="vc_col-sm-12 wpb_column column_container  jupiter-donut- _ jupiter-donut-height-full">
          <div
            id="mk-button-40"
            className="mk-button-container _ jupiter-donut-relative    jupiter-donut-block jupiter-donut-text-center sticky"
          >
            <a
              target="_self"
              className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
            >
              <span className="mk-button--text">
                BOOK A TRIAL SESSION
              </span>
            </a>
          </div>
        </div>
      </div>
      )
    }
    return (
                    <div
                    onClick={() => {
  history.push("/form-ems-egypt")
}}
                      id="mk-button-92"
                      className="mk-button-container sticky"
                      style={{ cursor: "pointer"}}
                    >
                      <a
                        target="_self"
                        className="mk-button js-smooth-scroll mk-button--dimension-flat mk-button--size-large mk-button--corner-pointed text-color-light _ jupiter-donut-relative jupiter-donut-text-center jupiter-donut-font-weight-700 jupiter-donut-no-backface  jupiter-donut-letter-spacing-2 jupiter-donut-inline-block"
                      >
                        <span className="mk-button--text">BOOK A TRIAL SESSION</span>
                      </a>
                    </div>
    )
}

