const BACK_ROUTE = "BACK_ROUTE";
const BACK_ROUTE_RESET = "BACK_ROUTE_RESET";

const backRouteReducer = (
  state = { backRoute: null, routeName: null },
  action
) => {
  switch (action.type) {
    case BACK_ROUTE:
      return { ...state, backRoute: action.payload.backRoute, routeName: action.payload.routeName };
    case BACK_ROUTE_RESET:
      return { ...state, backRoute: null, routeName: null };
    default:
      return { ...state };
  }
};

export default backRouteReducer;
