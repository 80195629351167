import React from 'react'
import Modal from "react-modal"
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
const AutoplaySlider = withAutoplay(AwesomeSlider);
 


const ImageModal = (props) => {

  let customers = [
"https://i.ibb.co/fMqn3MP/a1.jpg",
"https://i.ibb.co/Gvs9BST/a2.jpg",
"https://i.ibb.co/wSzTbNR/a3.jpg",
"https://i.ibb.co/YQGNWnH/a4.jpg",
"https://i.ibb.co/zr5qndb/a5.jpg",
"https://i.ibb.co/r49RgBL/a6.jpg",
"https://i.ibb.co/9cG7xQq/a7.jpg",
"https://i.ibb.co/Vt6RZf5/a8.jpg",
"https://i.ibb.co/HKG0QyC/a9.jpg",
"https://i.ibb.co/R3msHbc/a10.jpg",
"https://i.ibb.co/j3RqTKM/a11.jpg",
"https://i.ibb.co/txjmyyx/a12.jpg",
"https://i.ibb.co/qNL8Z1H/a13.jpg",
"https://i.ibb.co/Vgvhwyy/a14.jpg",
"https://i.ibb.co/dPc5zgK/a15.jpg",
"https://i.ibb.co/h2GWBV1/a16.jpg",
"https://i.ibb.co/0Ky1bgH/a17.jpg",
"https://i.ibb.co/NSF8bSJ/a18.jpg",
"https://i.ibb.co/RHMSfyq/a19.jpg",
"https://i.ibb.co/7293F9Z/a20.jpg",
"https://i.ibb.co/nzyfPHG/a21.jpg",
"https://i.ibb.co/t2yP7Lm/a22.jpg",
"https://i.ibb.co/ZdG3kj3/a23.jpg",
"https://i.ibb.co/K97gjYd/a24.jpg",
"https://i.ibb.co/RjM7Mnw/a25.jpg",
"https://i.ibb.co/PMZ22Bh/a26.jpg",
"https://i.ibb.co/6Ym1ZfD/a27.jpg",
"https://i.ibb.co/dPLCZph/a28.jpg",
"https://i.ibb.co/RQ5MjY4/a29.jpg",
"https://i.ibb.co/9wyBnn0/a30.jpg",
"https://i.ibb.co/YLGJ5qD/a31.jpg",
"https://i.ibb.co/6J0jYBS/a32.jpg",
  ]

  
  let studio = [
    "https://i.ibb.co/d2pG9pW/a8.jpg",
    "https://i.ibb.co/b5Zm9Vb/a1.jpg",
    "https://i.ibb.co/Wvbjjhj/a2.jpg",
    "https://i.ibb.co/KqR2Pc0/a3.jpg",
    "https://i.ibb.co/8XjBMrX/a4.jpg",
    "https://i.ibb.co/HpM1m4W/a5.jpg",
    "https://i.ibb.co/84DWhRX/a6.jpg",
    "https://i.ibb.co/M2TGKhb/a7.jpg",
    "https://i.ibb.co/CWMHQBh/a9.jpg",
    "https://i.ibb.co/ygQH0J2/a10.jpg",
    "https://i.ibb.co/KjH85sG/a11.jpg",
    "https://i.ibb.co/Zfrdc49/a12.jpg",
    "https://i.ibb.co/Pm5Yzq1/a13.jpg",
    "https://i.ibb.co/gmqJWmg/a14.jpg",
    "https://i.ibb.co/FXbSkwy/a15.jpg",
    "https://i.ibb.co/StCc2QJ/a16.jpg",
    "https://i.ibb.co/Rg7ywFW/a17.jpg",
    "https://i.ibb.co/bgXvPNn/a18.jpg",
    "https://i.ibb.co/gJJCVYc/a19.jpg",
    "https://i.ibb.co/6Nn42wF/a20.jpg",
    "https://i.ibb.co/7tFxPnB/a21.jpg",
  ]
  let trainers = [
   "https://i.ibb.co/VBvgkp9/DSC-5362-copy.jpg",
       "https://i.ibb.co/7WYddyQ/DSC-5432-copy.jpg",
       "https://i.ibb.co/0nGYhVh/DSC-5434-copy.jpg",
       "https://i.ibb.co/LQkNb3t/DSC-5452-copy.jpg",
       "https://i.ibb.co/rv5dxW1/DSC-5464-copy.jpg",
       "https://i.ibb.co/VwNGgfG/DSC-5470-copy.jpg",
       "https://i.ibb.co/9gz0wZX/DSC-5478-copy.jpg",
       "https://i.ibb.co/1L06YR1/DSC-5509-copy.jpg",
       "https://i.ibb.co/x3wf1gr/DSC-5516-copy.jpg",
       "https://i.ibb.co/2dGmR5T/DSC-5517-copy.jpg",
       "https://i.ibb.co/1Gw7NJL/DSC-5518-copy.jpg",
       "https://i.ibb.co/3YsnSRd/DSC-5525-copy.jpg",
       "https://i.ibb.co/bWSsJpt/DSC-5530-copy.jpg",
       "https://i.ibb.co/kBmYTRH/DSC-5536-copy.jpg",
       "https://i.ibb.co/0B6gg0P/DSC-5538-copy.jpg",
       "https://i.ibb.co/cC0x0Mj/DSC-5545-copy.jpg",
       "https://i.ibb.co/gzG8sd9/DSC-5548-copy.jpg",
       "https://i.ibb.co/Xykr5LW/DSC-5576-copy.jpg",
       "https://i.ibb.co/6PhwSND/DSC-5579-copy.jpg",
       "https://i.ibb.co/p36TTxk/DSC-5591-copy.jpg",
       "https://i.ibb.co/tKtvTpv/a1.jpg",
       "https://i.ibb.co/kMR59pK/a2.jpg",
       "https://i.ibb.co/MgSLqj6/a3.jpg",
       "https://i.ibb.co/6DVdhG6/a4.jpg",
       "https://i.ibb.co/rZ8JbZj/a5.jpg",
       "https://i.ibb.co/CszcmQQ/a6.jpg",
       "https://i.ibb.co/McgwdFR/a7.jpg",
       "https://i.ibb.co/gMQxYn6/a8.jpg",
       "https://i.ibb.co/mqC0Vvg/a9.jpg",
       "https://i.ibb.co/Kxk4kLy/a10.jpg",
       "https://i.ibb.co/tQvNYTP/a11.jpg",
       "https://i.ibb.co/s9705VW/a12.jpg",
       "https://i.ibb.co/dQdW5zG/a13.jpg",
       "https://i.ibb.co/pKL2Qbn/a14.jpg",
       "https://i.ibb.co/W3Jp3qv/a15.jpg",
       "https://i.ibb.co/gDPVDfN/a16.jpg",
       "https://i.ibb.co/JcV6QDK/a17.jpg",
       "https://i.ibb.co/NtJWDJg/a18.jpg",
       "https://i.ibb.co/3416TP0/a19.jpg",
       "https://i.ibb.co/G0w9QjS/a20.jpg",
       "https://i.ibb.co/RYCvMnw/a21.jpg",
       "https://i.ibb.co/Br7tgyc/a22.jpg",
  ]

  const moment = [
    "https://i.ibb.co/wMLGRkD/DSC2028-copy.jpg",
    "https://i.ibb.co/QbSDjY4/DSC2032-copy.jpg",
    "https://i.ibb.co/p3Pq6yf/DSC2037-copy.jpg",
    "https://i.ibb.co/bKVC7YJ/DSC2041-copy.jpg",
    "https://i.ibb.co/C620CDd/DSC2069-copy.jpg",
    "https://i.ibb.co/1Kmdxkh/DSC2064-copy.jpg",
"https://i.ibb.co/DrR8N4s/mo1.jpg",
"https://i.ibb.co/Pr7qyY6/mo2.jpg",
"https://i.ibb.co/Lzz2kJS/mo3.jpg",
"https://i.ibb.co/2c09Xq7/mo4.jpg",
"https://i.ibb.co/QjbKN3C/mo5.jpg",
"https://i.ibb.co/MkMRpLs/mo6.jpg",
"https://i.ibb.co/ScYLcR3/mo7.jpg",
"https://i.ibb.co/M5M0T6H/mo8.jpg",
"https://i.ibb.co/frfSYZ8/mo9.jpg",
"https://i.ibb.co/gwK76Wz/mo10.jpg",
"https://i.ibb.co/jbLG2fS/mo11.jpg",
"https://i.ibb.co/jbShCDQ/mo12.jpg",
"https://i.ibb.co/WzkXWqG/mo13.jpg",
"https://i.ibb.co/BgKVSwL/mo14.jpg",
"https://i.ibb.co/VxcRX1n/mo15.jpg",
"https://i.ibb.co/ZB7z5d3/mo16.jpg",
"https://i.ibb.co/RpYFbK1/mo17.jpg",
"https://i.ibb.co/bvmZjy5/mo18.jpg",
"https://i.ibb.co/Qv2L3zp/mo19.jpg",
"https://i.ibb.co/DzhZsr0/mo20.jpg",
"https://i.ibb.co/ZdjNPk0/mo21.jpg",
"https://i.ibb.co/CQMnHrh/mo22.jpg",
"https://i.ibb.co/51S5s9y/mo23.jpg",
"https://i.ibb.co/1TNw4DM/mo24.jpg",
"https://i.ibb.co/LPgN46n/mo25.jpg",
"https://i.ibb.co/16rZs6J/mo26.jpg",
"https://i.ibb.co/p4pW2MH/mo27.jpg",
"https://i.ibb.co/CQPmM5P/mo28.jpg",
"https://i.ibb.co/5B9JRbY/mo29.jpg",
"https://i.ibb.co/SR6k1GC/mo30.jpg",
"https://i.ibb.co/mBG5hTz/mo31.jpg",
"https://i.ibb.co/VJ8X9BB/mo32.jpg",
"https://i.ibb.co/8YLCByf/mo33.jpg",
"https://i.ibb.co/7rLnpCK/mo34.jpg",
"https://i.ibb.co/GW57Bk1/mo35.jpg",
"https://i.ibb.co/4Rz2yNr/mo36.jpg",
"https://i.ibb.co/HX5Sthn/mo37.jpg",
"https://i.ibb.co/b1F3GLd/mo38.jpg",
"https://i.ibb.co/5nCRkR6/mo39.jpg",
"https://i.ibb.co/47D1bWg/mo40.jpg",
"https://i.ibb.co/6gp1SFR/mo41.jpg",
"https://i.ibb.co/svn1dqN/mo42.jpg",
"https://i.ibb.co/D4CdpTd/mo43.jpg",
"https://i.ibb.co/h9TLpjm/mo44.jpg",
"https://i.ibb.co/hZrFLJJ/mo45.jpg",
"https://i.ibb.co/s9f4qSK/mo46.jpg",
"https://i.ibb.co/JsgdPWy/mo47.jpg",
"https://i.ibb.co/5BZ93Dh/mo48.jpg",
"https://i.ibb.co/zWMhBPd/mo49.jpg",
"https://i.ibb.co/cvsvYpk/mo50.jpg",
"https://i.ibb.co/X3XYb8w/mo51.jpg",
"https://i.ibb.co/LrmjmWx/mo52.jpg",
"https://i.ibb.co/xJ2Jv2c/mo53.jpg",
"https://i.ibb.co/55BqvWn/mo54.jpg",
"https://i.ibb.co/2ZJLsCW/mo55.jpg",
"https://i.ibb.co/m6BhF7P/mo56.jpg",
"https://i.ibb.co/zJLbnwY/mo57.jpg",
"https://i.ibb.co/b2wWV7g/mo58.jpg",
"https://i.ibb.co/tqJVmm1/mo59.jpg",
"https://i.ibb.co/VHdzSsT/mo60.jpg",
"https://i.ibb.co/2NHzGh5/mo61.jpg",
"https://i.ibb.co/9r3pkK9/mo62.jpg",
"https://i.ibb.co/Y7McSN2/mo63.jpg",
"https://i.ibb.co/J54p81K/mo64.jpg",
"https://i.ibb.co/BrvT0MP/1f-copy.jpg",
"https://i.ibb.co/T12hYws/DSC-2556-copy.jpg",
"https://i.ibb.co/6XG8BDq/DSC-2559-copy.jpg",
"https://i.ibb.co/0rGsMY0/DSC-2567-copy.jpg",
"https://i.ibb.co/4Jbs1KL/DSC-2572-copy.jpg",
"https://i.ibb.co/B69mLc6/DSC-2611-copy.jpg",
"https://i.ibb.co/NLdtNqQ/DSC-2621-copy.jpg",
"https://i.ibb.co/m95VYSM/DSC-2629-copy.jpg",
"https://i.ibb.co/0jv7X8y/DSC-2631-copy.jpg",
"https://i.ibb.co/1fsKjt0/DSC-2639-copy.jpg",
"https://i.ibb.co/swqBNv9/DSC-2645-copy.jpg",
"https://i.ibb.co/Bf02xQS/DSC-2648-copy.jpg",
"https://i.ibb.co/V06SM5g/DSC-5175-copy.jpg",
"https://i.ibb.co/gt6Zxfv/DSC-5226-copy.jpg",
"https://i.ibb.co/Sr3c64m/DSC-5228-copy.jpg",
"https://i.ibb.co/bPcQFGH/DSC-5241-copy.jpg",
"https://i.ibb.co/Kzywwpm/DSC-5245-copy.jpg",
"https://i.ibb.co/NKFZT7p/DSC-5273-copy.jpg",
"https://i.ibb.co/5hKLsC3/DSC-5278-copy.jpg",
"https://i.ibb.co/LCPH3B3/DSC-5288-copy.jpg",
"https://i.ibb.co/C9MRD4r/DSC-5291-copy.jpg",
"https://i.ibb.co/KsNb7GP/DSC-5303-copy.jpg",
"https://i.ibb.co/pRMQ2pJ/DSC-5313-copy.jpg",
"https://i.ibb.co/y42Rr4W/DSC-5315-copy.jpg",
"https://i.ibb.co/KxBcBDt/DSC-5316-copy.jpg",
"https://i.ibb.co/316pLL0/DSC-5317-copy.jpg",
"https://i.ibb.co/zftrjw0/DSC-5320-copy.jpg",
"https://i.ibb.co/TcMCrKt/DSC-5355-copy.jpg",
"https://i.ibb.co/JHL57TJ/DSC-5375-copy.jpg",
"https://i.ibb.co/1TwsySf/DSC-5378-copy.jpg",
"https://i.ibb.co/tcbMKTp/DSC-5387-copy.jpg",
"https://i.ibb.co/KNjPkFX/DSC-5423-copy.jpg",
"https://i.ibb.co/kSYVgvN/DSC-7874-copy.jpg",
"https://i.ibb.co/XybPQWm/DSC-7862-copy.jpg",
"https://i.ibb.co/C59h342/DSC-7795-copy.jpg",
"https://i.ibb.co/7tRsS8W/DSC-7793-copy.jpg",
"https://i.ibb.co/MgWSkTM/DSC-7789-copy.jpg",
"https://i.ibb.co/Khjpcvm/DSC-7785-copy.jpg",
"https://i.ibb.co/tPcfdb5/DSC-7784-copy.jpg",
"https://i.ibb.co/W0YHgMy/DSC-7783.jpg",
"https://i.ibb.co/HCcDBbn/DSC-7783-copy.jpg",
"https://i.ibb.co/3WNJCFr/DSC-7781-copy.jpg",
"https://i.ibb.co/tXYfH8D/DSC-7780-copy.jpg",
"https://i.ibb.co/R9xLcz2/DSC-7770-copy.jpg",
"https://i.ibb.co/VMTvFdb/DSC-7763-copy.jpg",
"https://i.ibb.co/9q631Tk/DSC-7761-copy.jpg",
"https://i.ibb.co/gv782G3/DSC-7758-copy.jpg",
"https://i.ibb.co/Pcb7Kph/DSC-7750-copy.jpg",
"https://i.ibb.co/jyxYdDp/DSC-7747-copy.jpg",
"https://i.ibb.co/NtQ2sRT/DSC-7739.jpg",
"https://i.ibb.co/88DGMDK/DSC-7739-copy.jpg",
"https://i.ibb.co/nD9VQBR/DSC-0360-copy.jpg",
"https://i.ibb.co/54TRMNV/DSC-0355-copy.jpg",
"https://i.ibb.co/QfJhxbM/DSC-7729-copy.jpg",
"https://i.ibb.co/wYkcNtQ/DSC-0349-copy.jpg",
"https://i.ibb.co/9H0ZSVN/DSC-7664-copy.jpg",
"https://i.ibb.co/18V3yk7/DSC-0320-copy.jpg",
"https://i.ibb.co/tYbmwmm/DSC-7656-copy.jpg",
"https://i.ibb.co/8z2jVrs/DSC-0311-copy.jpg",
"https://i.ibb.co/Kjz99bW/DSC-7655-copy.jpg",
"https://i.ibb.co/rZRtFdp/DSC-0306-copy.jpg",
"https://i.ibb.co/5M4NXCs/DSC-7654-copy.jpg",
"https://i.ibb.co/fp35x4y/DSC-0303-copy.jpg",
"https://i.ibb.co/9N8Z233/DSC-7652-copy.jpg",
"https://i.ibb.co/jWmrZgQ/DSC-0302-copy.jpg",
"https://i.ibb.co/yQX5gTH/DSC-7650-copy.jpg",
"https://i.ibb.co/7jj0Z9B/DSC-7648-copy.jpg",
"https://i.ibb.co/85QT9nR/DSC-0299-copy.jpg",
"https://i.ibb.co/mTgsDfT/DSC-7647-copy.jpg",
"https://i.ibb.co/VCvFnq4/DSC-0293-copy.jpg",
"https://i.ibb.co/pj6b7qP/DSC-7646-copy.jpg",
"https://i.ibb.co/M2Lb6bw/DSC-0287-copy.jpg",
"https://i.ibb.co/dGzZz9c/DSC-7645-copy.jpg",
"https://i.ibb.co/QN7jjqd/DSC-0284-copy.jpg",
"https://i.ibb.co/NjRxB1x/DSC-7644-copy.jpg",
"https://i.ibb.co/q1qpKDF/DSC-0281-copy.jpg",
"https://i.ibb.co/zhtxVCp/DSC-7642-copy.jpg",
"https://i.ibb.co/xjhHSg7/DSC-0278-copy.jpg",
"https://i.ibb.co/QK6q5KX/DSC-7640-copy.jpg",
"https://i.ibb.co/J3v3YXq/DSC-0275-copy.jpg",
"https://i.ibb.co/PYD1Zv4/DSC-7619-copy.jpg",
"https://i.ibb.co/G9w9Qfv/DSC-0271-copy.jpg",
"https://i.ibb.co/4phGPK8/DSC-7610-copy.jpg",
"https://i.ibb.co/W2zBcSk/DSC-0266-copy.jpg",
"https://i.ibb.co/7G4Dq9Q/DSC-7602-copy.jpg",
"https://i.ibb.co/f0DQJx2/DSC-0263-copy.jpg",
"https://i.ibb.co/hK7ZHnW/DSC-7597-copy.jpg",
"https://i.ibb.co/fq4qnK3/DSC-0261-copy.jpg",
"https://i.ibb.co/nmpk4Hg/DSC-7582-copy.jpg",
"https://i.ibb.co/nbv7QVY/DSC-7571-copy.jpg",
"https://i.ibb.co/3yGVPKV/DSC-0257-copy.jpg",
"https://i.ibb.co/c1vf4L4/DSC-7542-copy.jpg",
"https://i.ibb.co/GQ0t7JH/DSC2138-copy.jpg",
"https://i.ibb.co/P9ZTzD7/DSC-7540-copy.jpg",
"https://i.ibb.co/bJvST0P/DSC-7531-copy.jpg",
"https://i.ibb.co/pyyC12X/DSC2111-copy.jpg",
"https://i.ibb.co/PYQxLW5/DSC-7526-copy.jpg",
"https://i.ibb.co/GM67Spg/DSC2109-copy.jpg",
"https://i.ibb.co/5MtG2Gh/DSC-7522-copy.jpg",
"https://i.ibb.co/BsV63yQ/DSC-7521-copy.jpg",
"https://i.ibb.co/FHW00dd/DSC2106-copy.jpg",
"https://i.ibb.co/4YhGksT/DSC-7516-copy.jpg",
"https://i.ibb.co/R9GvVtJ/DSC2102-copy.jpg",
"https://i.ibb.co/tY5b7GJ/DSC-7515-copy.jpg",
"https://i.ibb.co/hD8XFbx/DSC2101-copy.jpg",
"https://i.ibb.co/vvPfbms/DSC-7512-copy.jpg",
  ]

  const dispatch = useDispatch()

    const {
        active,
        setActive
    } = props




  return(
<Modal
        isOpen={active}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100vh",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"0px",
            borderRadius: "none",
            width: "auto",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >

        { active &&
        <div className='modalImage'>
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                style={{zIndex:10}}
                onClick={() => {
                    
                    setActive(null)
                     }}
              />
        <AutoplaySlider
            timerHeight="0px"
            play={true}
            cancelOnInteraction={true} // should stop playing on user interaction
            interval={5000}
            bullets={false}
        >
        {active == "trainers" ? (
            trainers.map((x, i) => (
              <div key={i} style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}} >
                <img style={{
                  height:"100vh",
                  objectFit: "cover"
                }} src={x} />
              </div>
            ))
        ) : active == "studio" ? (
          studio.map((x, i) => (
            <div key={i} style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}} >
              <img style={{
                height:"100vh",
                objectFit: "cover"
              }} src={x} />
            </div>
          ))
        ) : active == "customers" ? (
           customers.map((x, i) => (
             <div key={i} style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}} >
               <img style={{
                 height:"100vh",
                 objectFit: "cover"
               }} src={x} />
             </div>
           ))
        ) : active == "moment" ? (
            moment.map((x, i) => (
            <div key={i} style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}} >
            <img style={{
              height:"100vh",
              objectFit: "cover"
            }} src={x} />
            </div>
            ))
        ) : null}
        </AutoplaySlider></div>
}
      </Modal>
  )}

  export default ImageModal
       