import { gql } from "apollo-boost";

const updateClientMutation = gql`
  mutation(
    $_id: ID
    $location: String
    $state: String
    $employee: ID
    $image: String
    $name: String
    $active: Boolean
    $nextCall: String
  ) {
    updateClient(
    _id:$_id
    location: $location
    state: $state
    employee: $employee
    image: $image
    name: $name
    active: $active
    nextCall: $nextCall
    ) {
    _id
    name
    phoneNumber
    location
    state
    employee
    image
    type
    feedback {
        name
        date
        client
        employee
        notes
      }
    active
    nextCall
    }
  }
`;

export default updateClientMutation;
