import { gql } from "apollo-boost";

const updateUserPasswordMutation = gql`
  mutation($password: String) {
    updateUserPassword(password: $password) {
      password
      email
      _id
    }
  }
`;

export default updateUserPasswordMutation;
