import React, { useEffect, useState } from "react" 
import { css } from "@emotion/css";
import ClipLoader from "react-spinners/ClipLoader";



export const DataSpinner = (props) => {
    
        const override = css`
    display: block;
    position: absolute;
    left: 25%;
    right: 25%;
  `;

        return (
          <div
            className={`clip_loader_spinner_container ${props.all ? "allScreen" : "notAll"}`}
            style={{ display: "flex" }} 
          > 
            
            <ClipLoader
              size={props.small == "s" || props.small == "sm" ? 20 :100}
              css={override}
              color={props.color ? props.color :props.small == "s" ? "white" : "rgb(44, 44, 52)"}
              loading={true}
            />
          </div>
        );
}