import { gql } from "apollo-boost";

const currentLoginQuery = gql`
  query(
   
    $date: String
    $user: ID
  ) {
    currentLogin( 
      
    date: $date
    user: {
        _id: $user
    }
    ) {
      _id
    login
    logout
    date
    user {
      _id
      name
      email
      phoneNumber
    }
    totalTime
  }
  }
`;

export default currentLoginQuery;
