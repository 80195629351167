import { faTrashAlt, faPen, faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LZString from "lz-string"
import React, { useEffect, useState } from "react"
import ConfirmDeleteModal from "./confirmDeleteModal"
import "./accountHeader.scss"
import blank from "../../../../assests/images/blank.png"
import { saveAs } from 'file-saver'
import moment from "moment"

const AccountHeader = (props) => {

    const {
        selectedAccount,
        type,
        updateUser,
        deleteUserFunc,
        deleteClientFunc,
        updateClient,
        resetUserPassword,
        editUser,
        user
    } = props

    const [toggleChecked, setToggleChecked] = useState(true)
    const [deleteModal, setDeleteModal] = useState(null)

    const downloadImage = () => {
      saveAs(LZString.decompressFromEncodedURIComponent(selectedAccount.image), `${selectedAccount.name}_Report_${moment().format("MMMM DD, YYYY")}.jpg`) // Put your image url here.
    }
    // useEffect(() => {
    //     if(selectedAccount) {
    //         console.log(selectedAccount)
    //     }
    // },[selectedAccount])
    // useEffect(() => {
    //     if(user) {
    //         console.log(user)
    //     }
    // },[user])
    return (
        <div className="detailsContent">
                <ConfirmDeleteModal 
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                userType={type}
                deleteClientFunc={deleteClientFunc}
                deleteUserFunc={deleteUserFunc}
                />
                <div className="contentLeft" style={{alignItems: type == "audit" ? "center" : "flex-start"}}>
                <div className={`contentAvatar ${selectedAccount && "selectedAccount"}`}>
                <img
                    onClick={downloadImage}
                    className="adminAvatar"
                    src={selectedAccount.photo ? selectedAccount.photo : selectedAccount.image ?  LZString.decompressFromEncodedURIComponent(selectedAccount.image) : blank}
                    alt={
                      selectedAccount.name[0]
                    }
                />
                </div>
                {type == "client" && (
                    <>
                    <div className="detailsAvatar detailsClients">
                        <div>
                            <div className="detailsName">
                            <div>{selectedAccount.name} - <span style={{color:"green"}}>Location: {selectedAccount.location}</span></div>
                            </div>
                            <div className="detailsName">
                            <div style={{color:"green"}}>{selectedAccount.type} - {selectedAccount.state || "Fresh" } <br/><span style={{color:"#393939", fontSize:"18px", fontWeight:"normal"}}>{selectedAccount.comment || "No comment from marketing team"}</span></div>
                            </div>
                            

                        </div>

                    </div>
                    <div className="detailsAvatar detailsClients detailsSmaller">                    
                    <div className="detailsNumber">
                      <div> <FontAwesomeIcon  style={{marginRight:".5rem"}} icon={faPhoneAlt}/> {selectedAccount.phoneNumber} </div>
                    </div>
                  </div>
                  </>
                )}                
                
                </div>
                <div className="contentRight">
                   
                <FontAwesomeIcon  style={{marginRight:"1rem", cursor:"pointer",
                color:"#3D80C2"}} onClick={() => editUser(selectedAccount)} icon={faPen} />
                {type !== "audit" && !user ? <FontAwesomeIcon  style={{marginRight:"1rem", cursor:"pointer", color:"red"}} onClick={() => setDeleteModal(selectedAccount)} icon={faTrashAlt} /> : user && user.user._id !== selectedAccount.id ? (
                  <FontAwesomeIcon  style={{marginRight:"1rem", cursor:"pointer"}} onClick={() => setDeleteModal(selectedAccount)} icon={faTrashAlt} />   
                ) : null}

                </div>
              </div>
    )
}

export default AccountHeader