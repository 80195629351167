import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, FormFeedback, Label, Input } from "reactstrap";

import ACTION_NEW_PASSWORD_NOT_INVALID from "../../../../actions/user/Login/NewPassword/Invalid/ACTION_NEW_PASSWORD_NOT_INVALID";
import ACTION_NEW_PASSWORD from "../../../../actions/user/Login/NewPassword/ACTION_NEW_PASSWORD";
import ACTION_NEW_PASSWORD_INVALID from "../../../../actions/user/Login/NewPassword/Invalid/ACTION_NEW_PASSWORD_INVALID";
import ACTION_NEW_PASSWORD_VALID from "../../../../actions/user/Login/NewPassword/Valid/ACTION_NEW_PASSWORD_VALID";
import ACTION_NEW_PASSWORD_NOT_VALID from "../../../../actions/user/Login/NewPassword/Valid/ACTION_NEW_PASSWORD_NOT_VALID";
import ACTION_NEW_PASSWORD_RESET from "../../../../actions/user/Login/NewPassword/ACTION_NEW_PASSWORD_RESET";
import show from "../../../../assests/icons/show.svg"
import hide from "../../../../assests/icons/hide.svg"


const UserNewPassword = (props) => {
  const dispatch = useDispatch();

  // New Password States
  const newPassword = useSelector(
    (state) => state.newPassword.new_password
  );
  const newPasswordInvalid = useSelector(
    (state) => state.newPasswordInvalid.new_password_invalid
  );
  const newPasswordValid = useSelector(
    (state) => state.newPasswordValid.new_password_valid
  );

  const passwordTyping = () => {
    dispatch(ACTION_NEW_PASSWORD_RESET());
    dispatch(ACTION_NEW_PASSWORD_NOT_INVALID());
  };

  // Regular Expression for Password Validation - must be at least 8 characters long, must contain at least 1 lowercase character, 1 uppercase character, and 1 number
  const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  const validatePassword = (e) => {
    const validPassword = passwordReg.test(e.currentTarget.value);
    dispatch(ACTION_NEW_PASSWORD(e.currentTarget.value.trim()));

    if (validPassword) {
      dispatch(ACTION_NEW_PASSWORD_NOT_INVALID());
      dispatch(ACTION_NEW_PASSWORD_VALID());
    } else {
      dispatch(ACTION_NEW_PASSWORD_INVALID());
      dispatch(ACTION_NEW_PASSWORD_NOT_VALID());
    }
  };
  const [type, setType] = useState(true)

  return (
    <FormGroup>
      <Label for="loginPassword">
       <div className="auth-label" > {!props.confirm && "New "} Password <div>
       {newPassword && newPassword.length < 8 ? (
        <div className="auth-error">
          Password must be at least 8 characters long.
        </div>
      ) : newPassword && newPasswordInvalid ? (
        <div className="auth-error">
          use at least 1 lowercase, 1 uppercase
          , and 1 number.
        </div>
      ) : null }
         </div> </div>
      </Label>
      <Input
        type={type ? "password" : "text"}
        name="createNewPassword"
        defaultValue={newPassword}
        maxLength={128}
        placeholder="Enter new password"
        className={`auth-input ${ newPassword === ""
        ? ""
        : newPasswordInvalid || newPassword.length < 8
        ? "auth-error-border"
        : ""}`}
        onChange={passwordTyping}
        onBlur={validatePassword}
        invalid={
          newPassword === ""
            ? false
            : newPasswordInvalid || newPassword.length < 8
            ? true
            : false
        }
        valid={
          newPassword === "" ? false : newPasswordValid ? true : false
        }

      />       
      <span className="errspan" onClick={() => setType(!type)}><img src={!type ? show : hide} /></span>

      
    </FormGroup>
  );
};

export default UserNewPassword;
