import { gql } from "apollo-boost";

const addUrlMutation = gql`
  mutation(
    $active: Boolean
  ) {
    addUrl(    
      active: $active
    ) {
    _id
    active
    }
  }
`;

export default addUrlMutation;
