import React, { useEffect, useState } from "react"
import "./index.scss"

const UserFeedback = ({feedback}) => {
 
    /////////////////////////////////////////////////////
    

    return(
    <div  className="reportsContainer areaContainer"> 
    <div className="userSquareHeader">
                    {feedback.location && <div
                    className="areaBar"
                    style={{marginBottom:"1rem"}}
                    >
                    <span>{feedback.location}</span>
                    </div>}
                    </div>
                    <div className="typeHeader areaBar" style={{color:"green"}}>{feedback.staffName ? feedback.staffName : "Un known"}</div>

            <div style={{marginBottom:"2.5rem"}} className="areaUpperContainer responsiveArea">
           
                    <div className="rightCards">                        
                    <div className="areaLowerContainer">                    
                <div className="areaCardType areaNotes">
                        <div className="typeHeader">notes</div>
                        <div className="typeContent">{feedback.notes}</div>
                    </div>
                </div>
                    </div>
                    </div>
        </div>

    )
}

export default UserFeedback