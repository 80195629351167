import { gql } from "apollo-boost";

const allClientsQuery = gql`
  query(
    $_id: ID
    $phoneNumber: String
    $name: String
    $email: String
    $employee: ID
  ) {
    allClients(
      _id: $_id
      phoneNumber: $phoneNumber
      name: $name
      email: $email
      employee: $employee
    ) {
      _id
      comment
      name
      email
      phoneNumber
      package
invitedBy
selectedTime
      location
      feedback {
        name
        date
        client
        employee
        notes
        staffName
        location
      }
      state
      employee
      image
      type
      status
      active
    }
  }
`;

export default allClientsQuery;
