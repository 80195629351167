import { gql } from "apollo-boost";

const clientQuery = gql`
  query(
    $_id: ID
    $phoneNumber: String
    $employee: ID
  ) {
    client(
      _id: $_id
      phoneNumber: $phoneNumber
      employee: $employee
    ) {
      _id
      name
      email
      comment
      phoneNumber
      location
      feedback {
        name
        date
        client
        employee
        notes
        staffName
        location
        createdAt
      }
      state
      selectedTime
      employee
      image
      type
      status
      package
invitedBy
      active
    }
  }
`;

export default clientQuery;
