import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";

import ACTION_LOGIN_EMAIL from "../../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL";
import ACTION_REGISTERED_CLIENT_FOUND_RESET from "../../../../actions/user/Login/RegisteredClientFound/ACTION_REGISTERED_CLIENT_FOUND_RESET";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";
import ACTION_LOGIN_EMAIL_INVALID from "../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_TEMPO_INVALID from "../../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_INVALID";
import ACTION_LOGIN_TEMPO_NOT_INVALID from "../../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID";


const LoginEmail = () => {
  const dispatch = useDispatch();

  // Email States
  const loginEmail = useSelector(state => state.loginEmail.login_email);
  const loginEmailInvalid = useSelector(
    state => state.loginEmailInvalid.login_email_invalid
  );
  const loginTempoInvalid = useSelector(
    state => state.loginTempoInvalid.login_tempo_invalid
  );
  const validateEmail = (email) => {
    if(email && !String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        dispatch(ACTION_LOGIN_TEMPO_INVALID());
      }
  };
  const emailTyping = e => {
    dispatch(ACTION_LOGIN_EMAIL(e.currentTarget.value.trim().toLowerCase()));
    dispatch(ACTION_REGISTERED_CLIENT_FOUND_RESET());
    dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
    dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
    dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
  };

  return (
    <FormGroup>
      <Label for="loginEmail">
        <div className="auth-label"><div>Email</div> <div>{loginEmailInvalid ? (
        <div className="auth-error">
            This email is already registered
        </div> 
      ) : loginTempoInvalid ? (
        <div className="auth-error">
            This email is not valid.
        </div> 
      ) : null}</div></div>
        
      </Label>
      <Input
        type="email"
        name="loginEmail"
        defaultValue={loginEmail}
        maxLength={128}
        placeholder="Email address"
        className={`auth-input ${ loginEmailInvalid
        ? "auth-error-border"
        : ""}`}
        onChange={emailTyping}
        onBlur={() => validateEmail(loginEmail)}
        invalid={loginEmail === "" ? false : loginEmailInvalid ? true : false}
      />
    </FormGroup>
  );
};

export default LoginEmail;
