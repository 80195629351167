import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import PhoneNumber from '../addClientModal/Number';
import Dropdown from "react-dropdown";
import ACTION_PHONE_NUMBER_RESET from '../../../actions/GuestCheckoutForm/PhoneNumber/ACTION_PHONE_NUMBER_RESET';


const BookModal = (props) => {


         
  const phoneNumber = useSelector((state) => state.phoneNumber.phone_number);
  const phoneIsValid = useSelector((state) => state.phoneIsValid.phone_valid);
  const dispatch = useDispatch()

    const {
        clientModal,
        setClientModal,
        setClientName,
        clientName,
        setClientMobile,
        inviteUser,
        emailAlreadyRegistered,
        setEmailAlreadyRegistered,
        setClientLocation,
        clientLocation,
        
    } = props

  return(
<Modal
        isOpen={clientModal}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"40px",
            borderRadius: "none",
            width: "auto",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
<FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    
                    setClientModal(null)
                    setClientName("")
                    setClientMobile("")
                    setEmailAlreadyRegistered("")
                    setClientLocation("")
                    dispatch(ACTION_PHONE_NUMBER_RESET())

                     }}
              />
        { clientModal && <div className="modal task-modal" style={{ height:"fit-content"}}>
               

          <h1 className="header" style={{width:"100%"}}>BOOK NOW </h1>
          <div className="form-group-container task-form">
          <div className="form-row">
            <div className="form-collection">
              <div className="form-label">Name</div>
              <input
                onChange={(e) => setClientName(e.target.value)}
                value={clientName}
                type="text"
                placeholder="Client name"
                className="form-input" 
              />
            </div>
            </div>
            <div className="form-row">
            <div className="form-collection">
            <PhoneNumber />            
            </div>
            </div>
            <div className="form-row">
            <div className="form-collection">
            <div className="form-label">
                  Location
                </div>
                <Dropdown
                menuClassName="underMenu"
                  options={['Maadi', "Madinty", "Zayed", "Masr", "5th"]}
                  onChange={(choice) =>
                    setClientLocation(choice.value)
                  }
                  value={clientLocation}
                  controlClassName="form-input"
                  
                /> 
          </div>
          </div>            
          </div>
          <div>
            <div
              className={`${ clientName && !emailAlreadyRegistered && clientLocation && phoneNumber && phoneIsValid ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={clientName && !emailAlreadyRegistered && clientLocation && phoneNumber && phoneIsValid ? inviteUser : null }
            >
              
              {"Go!"}

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default BookModal
       