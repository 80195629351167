import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, FormFeedback, Label, Input } from "reactstrap";
import {useQuery, useLazyQuery} from "@apollo/client"
import ACTION_PHONE_NUMBER_RESET from "../../../actions/GuestCheckoutForm/PhoneNumber/ACTION_PHONE_NUMBER_RESET";
import ACTION_PHONE_NUMBER from "../../../actions/GuestCheckoutForm/PhoneNumber/ACTION_PHONE_NUMBER";
import ACTION_PHONE_VALID from "../../../actions/PhoneNumberValidation/Valid/ACTION_PHONE_VALID";
import ACTION_PHONE_NOT_INVALID from "../../../actions/PhoneNumberValidation/Invalid/ACTION_PHONE_NOT_INVALID";
import ACTION_PHONE_NOT_VALID from "../../../actions/PhoneNumberValidation/Valid/ACTION_PHONE_NOT_VALID";
import ACTION_PHONE_INVALID from "../../../actions/PhoneNumberValidation/Invalid/ACTION_PHONE_INVALID";
import clientQuery from "../../../graphql/queries/client/clientQuery";

const PhoneNumber = (props) => {
  let style = props.style || {}
  const dispatch = useDispatch();
  // Phone Number States
  const phoneNumber = useSelector((state) => state.phoneNumber.phone_number);
  const phoneIsValid = useSelector((state) => state.phoneIsValid.phone_valid);
  const phoneIsInvalid = useSelector(
    (state) => state.phoneIsInvalid.phone_invalid
  );
  const [
    phoneNumberAlreadyRegistered,
    changePhoneNumberAlreadyRegistered,
  ] = useState(false);

  // Regular Expression for Phone Number Validation - allows only phone numbers in the format (xxx) xxx - xxx, with x values being digits
  const phoneNumberReg = /^(\(\d\d\d\))+\s+(\d\d\d)+\s+(-)+\s+(\d\d\d\d\d)$/g;

  // Regular Expression for Autocompleted Phone Numbers - allows phone numbers in the format 1xxxxxxxxxx, with x values being digits and the leading 1 country code being optional.
  const phoneNumberAutocompleteReg = /^(1*\d{11})$/g;

  const [
    client,
    {
      data: data,
      called: called,
      loading: loading,
      refetch: refetch
    },
  ] = useLazyQuery(clientQuery , {
    fetchPolicy: "no-cache",
  });
  const validatePhoneNumber = (number) => {
    const validPhoneNumber = phoneNumberReg.test(number);
    const validPhoneAutocomplete = phoneNumberAutocompleteReg.test(number);
    const confirmN =  number.substring(0,5) == "(010)" || number.substring(0,5) == "(012)" || number.substring(0,5) == "(011)" || number.substring(0,5) == "(015)"

    if (!phoneNumberAlreadyRegistered ) {
      if (validPhoneNumber && confirmN) {
        dispatch(ACTION_PHONE_VALID());
        dispatch(ACTION_PHONE_NOT_INVALID());
      } else {
        dispatch(ACTION_PHONE_NOT_VALID());
        dispatch(ACTION_PHONE_INVALID());
      }
    } else {
      dispatch(ACTION_PHONE_NOT_VALID());
      dispatch(ACTION_PHONE_INVALID());
    }
  };

  const handlePhoneNumber = (e) => {
    !props.type && client({
      variables:{
        phoneNumber:e.currentTarget.value
      }
       })
    validatePhoneNumber(e.currentTarget.value);
    props.sub ? props.onblur(e.currentTarget.value) : dispatch(ACTION_PHONE_NUMBER(e.currentTarget.value));
    props.type == "side" && props.searchClient(e.currentTarget.value, "side")

  };


  useMemo(() => {
    if (phoneNumber) {
      if (data && data.client) {
          if (
            data.client.phoneNumber === phoneNumber
          ) {
            changePhoneNumberAlreadyRegistered(true);
            dispatch(ACTION_PHONE_NOT_VALID());
            dispatch(ACTION_PHONE_INVALID());
        }
      }
    }
  }, [data, phoneNumber, dispatch]);

  const phoneNumberTyping = (e) => {
    if(props.type == "side") {
      let currentTyping = e.currentTarget.value;
      e.currentTarget.value = currentTyping;

    } else {
    let currentTyping = e.currentTarget.value;

    changePhoneNumberAlreadyRegistered(false);
    dispatch(ACTION_PHONE_NUMBER_RESET());

    // Formatting for US Phone Numbers
    if (currentTyping.length === 3) {
      currentTyping = currentTyping.split("");
      currentTyping.unshift("(");
      currentTyping.push(") ");

      currentTyping = currentTyping.join("");
    } else {
      if (currentTyping.length === 4) {
        if (
          currentTyping.indexOf("(") === 0 &&
          currentTyping.indexOf(")") < 0
        ) {
          currentTyping = currentTyping.split("");
          currentTyping.splice(currentTyping.indexOf("("), 1);

          currentTyping = currentTyping.join("");
        } else {
          if (
            currentTyping.indexOf("(") < 0 &&
            currentTyping.indexOf(")") < 0
          ) {
            currentTyping = currentTyping.split("");
            currentTyping.unshift("(");
            currentTyping.splice(4, 0, ") ");

            currentTyping = currentTyping.join("");
          }
        }
      } else {
        if (currentTyping.length === 6) {
          if (currentTyping.indexOf(" ") < 0) {
            currentTyping = currentTyping.split("");
            currentTyping.splice(5, 0, " ");

            currentTyping = currentTyping.join("");
          }
        } else {
          if (currentTyping.length === 10) {
            if (currentTyping.lastIndexOf(" ") === 5) {
              currentTyping = currentTyping.split("");
              currentTyping.splice(9, 0, " - ");

              currentTyping = currentTyping.join("");
            } else {
              if (currentTyping.indexOf("(") < 0) {
                currentTyping = currentTyping.split("");
                currentTyping.unshift("(");
                currentTyping.splice(4, 0, ") ");
                currentTyping.splice(8, 0, " - ");
                currentTyping = currentTyping.join("");
              }
            }
          } else {
            if (currentTyping.length === 11) {
              if (currentTyping.lastIndexOf(" ") === 5) {
                currentTyping = currentTyping.split("");
                currentTyping.splice(9, 0, " - ");
  
                currentTyping = currentTyping.join("");
              } else {
                if (currentTyping.indexOf("(") < 0) {
                  currentTyping = currentTyping.split("");
                  currentTyping.unshift("(");
                  currentTyping.splice(4, 0, ") ");
                  currentTyping.splice(8, 0, " - ");
                  currentTyping = currentTyping.join("");
                }
              }
            } else {
              if (currentTyping.length === 12) {
                if (currentTyping.lastIndexOf(" ") === 9) {
                  currentTyping = currentTyping.split("");
                  currentTyping.splice(11, 0, " ");

                  currentTyping = currentTyping.join("");
                }
              }
            }
          }
        }
      }}
      e.currentTarget.value = currentTyping;
    }
  };

  const phoneNumberKeyTyping = (e) => {
    if (
      (e.keyCode >= 8 && e.keyCode < 32) ||
      (e.keyCode >= 37 && e.keyCode <= 40) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode == 17) || 
      (e.keyCode == 86) || props.type == "side"    
    ) {
      return e.keyCode;
    } else {
     e && e.preventDefault();
    }
  };
  return (
    <FormGroup>
      <Input
        type="tel"
        name="phoneNumber"
        style={{...style}}
        maxLength={17}
        onKeyDown={phoneNumberKeyTyping}
        defaultValue={props.sub ? props.value : phoneNumber}
        placeholder="(000) 000-00000"
        onChange={phoneNumberTyping}
        onBlur={handlePhoneNumber}
        className="form-input"
        invalid={phoneNumber === "" ? false : phoneIsInvalid ? true : false}
        valid={phoneNumber === "" ? false : phoneIsValid ? true : false}
      />
      {props.type!== "side" && !props.sub ? phoneNumber ? phoneNumberAlreadyRegistered ? (
        <div className="auth-error">
          This phone number has already been registered.
        </div>
      ) : phoneIsInvalid && !props.sub ? (
        <div className="auth-error">
          Please enter a valid phone number.
        </div>
      ) : null : null : null}
    </FormGroup>
  );
};

export default PhoneNumber;