import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";

import ACTION_LOGIN_PASSWORD from "../../../../actions/user/Login/LoginPassword/ACTION_LOGIN_PASSWORD";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";
import show from "../../../../assests/icons/show.svg"
import hide from "../../../../assests/icons/hide.svg"

const LoginPassword = (props) => {
  const { handleLoginClick } = props;
  const dispatch = useDispatch();

  // Password States
  const loginPassword = useSelector(
    (state) => state.loginPassword.login_password
  );
  const loginPasswordInvalid = useSelector(
    (state) => state.loginPasswordInvalid.login_password_invalid
  );

  const passwordTyping = (e) => {
    dispatch(ACTION_LOGIN_PASSWORD(e.currentTarget.value.trim()));
    dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
    dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
  };

  const [type, setType] = useState(true)

  return (
    <FormGroup>
      <Label for="loginPassword">
        <div className="auth-label"><div>Password</div> <div>
        {loginPasswordInvalid ? (
                      <div className="auth-error">
                      Invalid password.
                      </div>
      ) : null}
          </div></div>
      </Label>
      <Input
        type={type ? "password" : "text"}
        name="loginPassword"
        defaultValue={loginPassword}
        maxLength={128}
        placeholder="Password"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLoginClick();
          }
        }}
        className="auth-input"
        onChange={passwordTyping}
        invalid={
          loginPassword === "" ? false : loginPasswordInvalid ? true : false
        }
      />
      <span className="errspan" onClick={() => setType(!type)}><img src={!type ? show : hide} /></span>
    </FormGroup>
  );
};

export default LoginPassword;
