import { gql } from "apollo-boost";

const validUrlMutation = gql`
  mutation(
    $_id: String
  ) {
    validUrl(    
      _id: $_id
    ) {
    _id
    active
    }
  }
`;

export default validUrlMutation;
