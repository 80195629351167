import { gql } from "apollo-boost";

const updateUserMutation = gql`
  mutation(
    $_id: ID
    $name: String,
    $email: String,
    $phoneNumber: String,
    $active: Boolean,    
    $role: String
    $image: String
    $org: String
    $group: String
    $types: [String],
  $feedbacks: [String],
  $locations: [String],

  ) {
    updateUser(
    _id: $_id
    name: $name
    email: $email
    phoneNumber: $phoneNumber
    active: $active
    role: $role
    image: $image
    org: $org
    group: $group
      types: $types
    feedbacks: $feedbacks
    locations: $locations
    
    ) {
      _id
      name
      email
      phoneNumber
      active 
      role
      image
      org
      group
      types
      feedbacks
      locations
    }
  }
`;

export default updateUserMutation;
