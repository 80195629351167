import React, { useEffect, useState } from "react"
import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import AdminSideBar from "./adminSideBar"
import AdminSignin from "./adminSignin"
import AdminSignup from "./adminSignin/Signup"
import Header from "../header"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons"
import ACTION_ACTIVE_SIDE_BAR from "../../actions/ActiveSideBar/ACTION_ACTIVE_SIDE_BAR"
import AdminDashboard from "./adminDashboard"
import AdminCampaign from "./adminCampaign"
import { useLazyQuery } from "@apollo/react-hooks"
import clientQuery from "../../graphql/queries/client/clientQuery"
import currentLoginQuery from "../../graphql/queries/user/currentLoginQuery"
import moment from "moment"
import allUsersQuery from "../../graphql/queries/user/allUsersQuery"
import AdminManage from "./adminManage"

const AdminRouter = (props) => {
    const phoneNumber = useSelector((state) => state.phoneNumber.phone_number);
    const dispatch = useDispatch()
    const userAuthenticated = useSelector((state) => state.userAuthenticated.user_authenticated);
    const [allSales, setAllSales] = useState(null)
 
    const {
        path ,
        user, 
        handleLogout
    } = props
    
    const activeSideBar = useSelector((state) => state.activeSideBar.active);

    const history = useHistory()

        
    const [
      client,
      { loading: clientLoading, data:clientData, refetch: clientRefetch, error: clientError },
    ] = useLazyQuery(clientQuery,{
      fetchPolicy: 'no-cache'
    });
  
    
    const [
      currentLogin,
      {
        data: currentLoginData,
        called: currentLoginCalled,
        loading: currentLoginLoading,
        refetch: currentLoginRefetch
      },
    ] = useLazyQuery(currentLoginQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      allUsers,
      {
        data: allUsersData,
        called: allUsersCalled,
        loading: allUsersLoading,
        refetch: allUsersRefetch
      },
    ] = useLazyQuery(allUsersQuery, {
      fetchPolicy: "no-cache",
    });

  
  useEffect(() => {
    if(user) {
      allUsers({
        variables:{
          role:"sales"
        }
      })
      currentLogin({  
        variables:{
          user: user.user._id,
          date: moment().format("MMMM DD, YYYY")
        }
      }).then((x) => {
        console.log(x)
      })
    } 
     
  },[user])

  const [staff, setStaff] = useState(null)
  useEffect(() => {
    if(allUsersData) {
      console.log(allUsersData)
      setStaff(allUsersData.allUsers)
      let allSales = allUsersData.allUsers.map(x => {
        return {
          label: x.name,
          value: x._id
        }
      })
      setAllSales(allSales)
    }
  },[allUsersData])  
 
    const searchClient = (o, type) => {
      let formatNumber = (nu) => {
        return `(${nu[0]}${nu[1]}${nu[2]}) ${nu[3]}${nu[4]}${nu[5]} - ${nu[6]}${nu[7]}${nu[8]}${nu[9]}${nu[10]}`
        }
      if(o) {
        let oN = formatNumber(o)
        history.push("/admin/campaign")
        client({
          variables:{
            phoneNumber: type == "side" ? oN : o
          }
        })
       
      } else {        
        
        setActiveClient(null)
        if(user.user.role == "sales") {
          client({
          variables:{
            employee: user.user._id
          }
        })
        } 
      }
   }
   useEffect(() => {
    if(user && user.user.role == "sales") { 
      client({
        variables:{
          employee: user.user._id
        }
      })
    }
   },[user])

   useEffect(() => {
    if(clientData) {
      setActiveClient(clientData.client)
    }
   },[clientData])

   const [activeClient, setActiveClient] = useState(null)


   useEffect(() => {
    if(clientError) {
      clientError.message !== "unlucky" ? setActiveClient("Not Found") : setActiveClient(null)
    }
   },[clientError])

    // useEffect(() => {
    //   if(userAuthenticated) {
    //     history.push("/admin/dashboard")
    //   }
    // },[userAuthenticated])

    return (
        <>

      {userAuthenticated && (
        <>
      <AdminSideBar allSales={allSales} currentLoginData={currentLoginData} currentLoginRefetch={currentLoginRefetch} searchClient={searchClient} user={user} path={path} handleLogout={handleLogout}/>
      { !activeSideBar && <div className="toggleMenuSideBarContainer">
        <div className="toggleMenuSideBar">
            <FontAwesomeIcon onClick={() => dispatch(ACTION_ACTIVE_SIDE_BAR())} style={{fontSize:"30px", color:"white", marginLeft:".2rem"}} className="toggleMenuSideBarIcon" icon={faArrowCircleRight} />
        </div>
      </div> }
      </>
      )}

      <div className="adminContainer">
      <Switch>
        <Route
          exact
          path={path}
          render={() => (
            <AdminSignin user={user} />
          )}
        />
                <Route
          exact
          path={"/admin/signup/:id"}
          render={({match}) => (
            <AdminSignup id={match.params.id}/>
          )}
        />

        <Route
          exact
          path={"/admin/campaign"}
          render={() => (
            <AdminCampaign
            user={user}
            activeClient={activeClient}
            setActiveClient={setActiveClient}
            client={client}
            clientData={clientData}
            clientLoading={clientLoading}
            clientRefetch={clientRefetch}
            />
          )}
        />

<Route
          exact
          path={"/admin/manage"}
          render={() => (
            <AdminManage
            user={user}
            staff={staff}
            allUsersRefetch={allUsersRefetch}
            />
          )}
        />

        
        <Route
          exact
          path={"/admin/dashboard"}
          render={() => (
            <AdminDashboard
            user={user}
            staff={staff}
            allSales={allSales}
            allUsersRefetch={allUsersRefetch}
            searchNumber={searchClient}
            />
          )}
        />

        
        {/* If no path matches, redirect to home */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      </div>

      </>
    )
}

export default AdminRouter