import { gql } from "apollo-boost";

const allUsersQuery = gql`
  query(
    $role: String
  ) {
    allUsers(
    role: $role
     
    ) {
      _id
      name
      email
      phoneNumber
      active
      firstTime
      role
      image
      org
      group
      types
      feedbacks
      locations
    }
  }
`;

export default allUsersQuery;